import React, { FC } from 'react';

type CardPricePropsType = {
  price: string;
}

const CardPrice: FC<CardPricePropsType> = ({ price = '-' }) => (
  <div className='flex justify-center items-center lg:justify-end m-auto lg:m-0 xl:m-auto w-full md:w-[495px] lg:w-full xl:w-[495px]'>
    <div className='flex flex-col gap-4 lg:w-[450px]'>
      <span className='text-sm'>Price:</span>
      <span className='text-xl md:text-[1.625rem] md:leading-tight'>{price}</span>
    </div>
  </div>
);

export default CardPrice;
