import parse from 'html-react-parser';
import React, { FC } from 'react';
import NcImage from '../../../shared/NcImage/NcImage';
import ShareButton from '../../../shared/ShareButton/ShareButton';
import PostCardMeta from '../../blog/components/PostCardMeta';

type ArticleSectionPropsType = {
  content: string
  date: string
  description: string
  imageUrl: string
  readingTime: string
  title: string
}

const ArticleSection: FC<ArticleSectionPropsType> = ({
  content,
  date,
  description,
  title,
  readingTime,
  imageUrl,
}) => (
  <section className='flex flex-col gap-6 w-auto xl:w-[850px] lg:w-[635px]'>
    <div className='flex justify-between items-center'>
      <PostCardMeta date={date} readingTime={readingTime} />
      <ShareButton
        provider={title}
        image={imageUrl || undefined}
        isArticle
      />
    </div>

    <h2 className='block text-[32px] leading-tight font-semibold text-primary-800'>
      {title}
    </h2>

    <div className='prose min-w-full'>
      {description ? parse(description) : ''}
    </div>

    <div className='rounded-[20px] overflow-hidden'>
      <NcImage
        className='h-[300px] md:h-[325px] xl:h-[520px] object-cover w-full bg-secondary-300'
        src={imageUrl}
        title={title}
      />
    </div>

    <div className='prose min-w-full'>
      {content ? parse(content) : ''}
    </div>
  </section>
);

export default ArticleSection;
