import React, { FC } from 'react';

type ProviderTitleStateProps = {
  name: string
}

const ProviderTitle: FC<ProviderTitleStateProps> = ({ name }) => (
  <div className='flex justify-between items-center w-full pr-[40px] md:pr-[96px]'>
    <h2 className='text-2xl md:text-[1.75rem] lg:text-[2rem] font-extrabold'>
      {name}
    </h2>
  </div>
);

export default ProviderTitle;
