import { ProviderPromo } from '../types/articleTypes';

// eslint-disable-next-line import/prefer-default-export
export const providersPromoMock: ProviderPromo[] = [
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour1',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour2',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour3',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour4',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour5',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour6',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour7',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour8',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour9',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour10',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour11',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour12',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour13',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour14',
  },
  {
    previewImg: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    programUrl: 'bali/ubud/providers/black-panther-ubud/class-program/parkour',
    providerUrl: 'bali/ubud/providers/black-panther-ubud',
    name: 'Black Panther Ubud',
    program: 'Parkour15',
  },
];
