// eslint-disable-next-line no-shadow
enum AppStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  NOT_FOUND = 'notFound',
}

export default AppStatus;
