import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import NcImage from '../../../shared/NcImage/NcImage';
import { ArticlePromo } from '../types/articleTypes';

type ArticleSidebarPropsType = {
  articlesPromo: ArticlePromo[]
}

const ArticleSidebar: FC<ArticleSidebarPropsType> = ({ articlesPromo }) => {
  const { width } = useWindowSize();
  const numElements = width < 768 ? 2 : 3;
  const articlesPromoForShown = articlesPromo.slice(0, numElements);

  return (
    <section className='flex flex-col gap-6 w-full lg:w-[260px] xl:w-[310px]'>
      <h3 className='uppercase font-extrabold'>Discover more interesting </h3>
      <div className='flex flex-col md:flex-row md:items-center lg:flex-col gap-4'>
        {articlesPromoForShown.map((article, index) => (
          <Link
            to={`/blog/${article.slugName}`}
            key={index}
            className='flex flex-col w-auto gap-2 md:gap-4 lg:w-full'
          >
            <div className='overflow-hidden group rounded-md'>
              <NcImage
                containerClassName='w-full'
                className='nc-will-change-transform xl:h-[210px] h-[170px] object-cover w-full group-hover:scale-110 transform transition-transform duration-300 bg-secondary-300'
                src={article.imageUrl}
              />
            </div>
            <span className='font-extrabold'>
              {article.title}
            </span>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default ArticleSidebar;
