import React, { FC } from 'react';
import star from '../../../assets/images/provider-card/rating-star.svg';

type ProviderRatingPropsType = {
  googleMapsRating: number | null
  googleMapsID: string
}

const ProviderRating: FC<ProviderRatingPropsType> = ({ googleMapsRating, googleMapsID }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {googleMapsID && googleMapsRating
      ? (
        <div className='flex gap-1 lg:gap-1.5 items-center h-fit md:h-4'>
          <span className='text-base md:text-lg'>{googleMapsRating}</span>
          <img src={star} alt='rating star' className='w-4 h-4' title='star' />
          <span
            className='text-sm md:text-base'
          >
            by Google
          </span>
        </div>
      )
      : null}
  </>
);

export default ProviderRating;
