import React, { ButtonHTMLAttributes, FC } from 'react';

export interface ButtonProps {
  className?: string;
  translate?: string;
  sizeClass?: string;
  fontSize?: string;
  //
  loading?: boolean;
  disabled?: boolean;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  href?: string
  targetBlank?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

const Button: FC<ButtonProps> = ({
  className = 'text-white',
  translate = '',
  sizeClass = 'px-8 py-3',
  fontSize = 'text-lg font-extrabold',
  disabled = false,
  href,
  children,
  type,
  loading,
  onClick = () => {},
}) => {
  const CLASSES = `nc-Button relative h-auto flex items-center justify-center rounded-full transition-colors hover:shadow-[0_4px_20px_rgba(0,0,0,0.15)] ${fontSize} ${sizeClass} ${translate} ${className}`;

  const _renderLoading = () => (
    <svg
      className='animate-spin -ml-1 mr-3 h-5 w-5'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
    >
      <circle
        className='opacity-25'
        cx='12'
        cy='12'
        r='10'
        stroke='currentColor'
        strokeWidth='3'
      />
      <path
        className='opacity-75'
        fill='currentColor'
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      />
    </svg>
  );

  return (
    <button
      disabled={disabled || loading}
      className={`${CLASSES}`}
      onClick={onClick}
      type={type}
    >
      {loading && _renderLoading()}
      {children || 'This is Button'}
    </button>
  );
};

export default Button;
