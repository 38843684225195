import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { providerAPI } from '../../../api/provider-api';
import { setAppError, setAppStatus } from '../../../app/state/appReducer';
import AppStatus from '../../../app/types/appStatusTypes';
import type { RootState } from '../../../store';
import { ProviderLetterList } from '../types/allProvidersTypes';

type AllProviderStateType = {
  allProviders: ProviderLetterList[]
}

const initialState: AllProviderStateType = {
  allProviders: [],
};

export const getAllProviders = createAsyncThunk(
  'getAllProviders',
  async (data, { dispatch, getState }) => {
    const { allProviders } = (getState() as RootState).allProviders as AllProviderStateType;
    if (allProviders.length > 0) {
      return { statusCode: 200, providers: allProviders };
    }
    try {
      dispatch(setAppStatus(AppStatus.LOADING));
      const response = await providerAPI.getProvidersList();
      dispatch(setAppStatus(AppStatus.SUCCEEDED));
      return response.data;
    } catch (err: any) {
      dispatch(setAppError('class program not found'));
      return Promise.reject(err.message);
    }
  },
);

const allProviderSlice = createSlice({
  name: 'allProviders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProviders.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          state.allProviders = action.payload.providers;
        }
      });
  },
});

export default allProviderSlice.reducer;
