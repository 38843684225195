import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import separator from '../../../assets/images/provider-card/breadcrumbsSeparator.svg';
import arrow from '../../../assets/images/provider-card/link-arrow.svg';

type ArticleBreadcrumbsProps = {
  articleTitle: string
}

const ArticleBreadcrumbs: FC<ArticleBreadcrumbsProps> = ({ articleTitle }) => (
  <div className='flex items-center gap-[18px]'>
    <Link
      to='/blog'
      className='text-base md:text-lg text-primary-400 font-extrabold flex gap-2.5 items-center'
    ><img src={arrow} alt='arrow' className='md:hidden rotate-180' title='arrow' />
      Main
    </Link>
    <img src={separator} alt='separator' className='hidden md:block' title='separator' />
    <span
      className='hidden md:block text-base md:text-lg text-primary-400 flex gap-2.5 items-center'
    >
      {articleTitle}
    </span>
  </div>
);

export default ArticleBreadcrumbs;
