import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import AppStatus from '../../app/types/appStatusTypes';
import CardBreadcrumbs from '../../common/Card/CardBreadcrumbs';
import CardCityDistrict from '../../common/Card/CardCityDistrict';
import CardContact from '../../common/Card/CardContacts';
import CardDescription from '../../common/Card/CardDescription';
import CardDetailsList, { DetailItem } from '../../common/Card/CardDetailsList';
import CardPhotos from '../../common/Card/CardPhotos';
import CardPrice from '../../common/Card/CardPrice';
import { CardSocial } from '../../common/Card/CardSocialList';
import CardTitle from '../../common/Card/CardTitle';
import LoadingProgramPage from '../../common/Loading/LoadingProgramPage';
import { CardPath } from '../../routes/types/routerTypes';
import ShareButton from '../../shared/ShareButton/ShareButton';
import { useAppDispatch, RootState } from '../../store';
import ProviderRating from '../provider/components/ProviderRating';
import { searchPlayground } from './state/playgroundReducer';
import { PlaygroundParamsType, PlaygroundType } from './types/playgroundTypes';

const PlaygroundCard = () => {
  const {
    district, name, city,
  } = useParams<keyof PlaygroundParamsType>() as PlaygroundParamsType;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchPlayground({ district, name, city }));
  }, []);

  const cardData: unknown = useSelector<RootState>((state) => state.playground.playground);
  const appStatus = useSelector<RootState>((state) => state.appState.status);

  assertPlaygroundData(cardData);

  const playgroundDetails: DetailItem[] = [
    { detail: 'Age:', content: `${cardData.age} years` },
    { detail: 'Language:', content: cardData.language },
    { detail: 'Schedule:', content: cardData.schedule },
    { detail: 'Leave child:', content: `${cardData.dropOffOption ? 'yes' : 'no'}` },
  ];

  const cardSocial: CardSocial[] = [
    { name: 'instagram', link: cardData.instagramLink },
    { name: 'whatsapp', link: `https://wa.me/${cardData.whatsappLink}` },
    { name: 'telegram', link: cardData.telegram },
    { name: 'siteLink', link: cardData.siteLink },
  ];

  if (appStatus === AppStatus.NOT_FOUND) {
    return <Navigate to='/404' />;
  }

  if (appStatus === AppStatus.LOADING) {
    return <LoadingProgramPage />;
  }

  return (
    <main className='container relative z-10 flex flex-col w-full mb-[50px] mb-[70px] grow gap-6 '>
      <Helmet>
        <title>{`Kidspace: ${cardData.name}`}</title>
        <meta name='keywords' content={cardData.tags ? cardData.tags.join(' ') : ''} />
      </Helmet>
      <h1 className='visually-hidden'>{cardData.name}</h1>
      <CardBreadcrumbs
        cardPath={CardPath.PLAYGROUND}
        city={cardData.city?.slugName}
        district={cardData.district?.slugName}
        provider={cardData.name}
        providerSlugName={null}
        program={null}
      />
      <div className='flex flex-col lg:rounded-[20px] lg:border lg:border-neutral-200 lg:p-8 gap-6'>
        <CardPhotos photos={cardData.images} />
        <div className='relative w-full flex flex-col gap-4 md:gap-3 lg:gap-2 md:pt-2'>
          <div className='absolute right-2 top-2'>
            <ShareButton
              city={cardData.city?.name}
              district={cardData.district?.name}
              provider={cardData.name}
              program={cardData.type}
              image={cardData.images ? cardData.images[0] : undefined}
            />
          </div>
          <CardTitle
            city={city}
            district={district}
            name={cardData.name}
            type={cardData.type}
            slugName={cardData.slugName}
            cardPath={null}
          />
          <div className='flex md:justify-start md:items-center items-start gap-10'>
            <ProviderRating
              googleMapsRating={cardData.googleMapsRating}
              googleMapsID={cardData.googleMapsID}
            />
            <CardCityDistrict
              city={cardData.city?.name}
              district={cardData.district?.name}
              googleMapsID={cardData.googleMapsID}
              className='flex-col md:flex-row gap-0'
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row gap-6 xl:gap-0 items-start justify-between flex-wrap'>
          <div className='xl:w-fit'>
            <CardContact phone={cardData.whatsappLink} socials={cardSocial} />
          </div>
          <div className='grow xl:grow-0'>
            <CardPrice price={cardData.price} />
          </div>
          <div className='xl:w-[357px] w-full'>
            <CardDetailsList details={playgroundDetails} />
          </div>
        </div>
        <CardDescription
          description={cardData.description}
          comments={cardData.comments}
        />
      </div>
    </main>
  );
};

export default PlaygroundCard;

function assertPlaygroundData(spaceData: unknown): asserts spaceData is PlaygroundType {
  if (typeof spaceData === 'object' && spaceData !== null) {
    return;
  }
  throw new Error('The playground data is not an object');
}
