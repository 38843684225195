import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppStatus from '../types/appStatusTypes';

type AppStateType = {
  status: AppStatus
  error?: string
}

const initialState: AppStateType = {
  status: AppStatus.IDLE,
};

const appStateSlice = createSlice({
  name: 'appStatus',
  initialState,
  reducers: {
    setAppStatus: (state, action: PayloadAction<AppStatus>) => {
      state.status = action.payload;
    },
    setAppError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { setAppStatus, setAppError } = appStateSlice.actions;

export default appStateSlice.reducer;
