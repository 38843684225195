import React, { FC } from 'react';

type CardCityDistrictPropsType = {
  city: string,
  district: string
  googleMapsID?: string,
  className?: string;
}

const CardCityDistrict: FC<CardCityDistrictPropsType> = ({
  city,
  district,
  googleMapsID,
  className,
}) => (
  <div className={`${className} flex md:gap-2 items-start md:items-center`}>
    <span className='min-w-fit text-base md:text-lg'>{city}, {district}</span>
    {googleMapsID && (
    <a
      target='_blank'
      title='on the map'
      className='underline text-primary-400 text-sm font-extrabold min-w-fit'
      href={`https://www.google.com/maps/search/?api=1&query=%3Caddress%3E&query_place_id=${googleMapsID}`}
      rel='noreferrer'
    >
      on the map
    </a>
    )}
  </div>
);

export default CardCityDistrict;
