import React, { FC } from 'react';
import parse from 'html-react-parser';

type CardDescriptionPropsType = {
  description: string;
  comments: string;
}

const CardDescription: FC<CardDescriptionPropsType> = ({ description, comments = '-' }) => {
  const parsedDescription = description ? parse(description) : '';
  return (
    <div className='flex flex-col justify-between lg:flex-row gap-6 pt-4 md:pt-8 border-t border-neutral-200'>
      <div className='xl:w-[800px] lg:w-[400px] flex flex-col items-start gap-3 xl:gap-2.5'>
        <span className='text-base md:text-lg font-extrabold'>Description</span>
        <div className='text-base md:text-lg'>{parsedDescription}</div>
      </div>
      <div className='xl:w-[357px] lg:w-[450px] flex flex-col items-start gap-3 xl:gap-2.5'>
        <span className='text-base md:text-lg font-extrabold'>Comments</span>
        <div className='text-base md:text-lg'>{comments}</div>
      </div>
    </div>
  );
};

export default CardDescription;
