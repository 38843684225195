import React, { FC } from 'react';

import telegramImg from '../../assets/images/socials/telegram.svg';
import whatsappImg from '../../assets/images/socials/whatsapp.svg';
import instagramImg from '../../assets/images/socials/instagram.svg';
import siteLinkImg from '../../assets/images/socials/siteLink.svg';

const socialImages = [
  { name: 'instagram', image: instagramImg },
  { name: 'whatsapp', image: whatsappImg },
  { name: 'telegram', image: telegramImg },
  { name: 'siteLink', image: siteLinkImg },
];

export type CardSocial = {
  name: string,
  link: string,
}

type CardSocialListPropsType = {
  cardSocials: CardSocial[]
}

const CardSocialList: FC<CardSocialListPropsType> = ({ cardSocials }) => (
  <div className='flex items-center space-x-2'>
    {cardSocials.filter((social) => social.link !== '' && social.link !== '-')
      .map((social, i) => (
        <a
          key={i}
          title={social.name}
          href={social.link}
          target='_blank'
          className='w-8 h-8 rounded-full bg-secondary-300 hover:shadow-[0_4px_20px_rgba(0,0,0,0.15)] focus:outline-none flex items-center justify-center'
          rel='noreferrer'
        >
          <span className='sr-only'>{social.name}</span>
          <img src={socialImages.filter((img) => img.name === social.name)[0].image} alt={social.name} title={social.name} className='w-4 w-4' />
        </a>
      ))}
  </div>
);

export default CardSocialList;
