import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { CardPath } from '../../routes/types/routerTypes';

type CardTitleStateProps = {
  type: string,
  city: string,
  district: string,
  name: string
  slugName?: string,
  cardPath: CardPath | null
}

const CardTitle: FC<CardTitleStateProps> = ({
  type, slugName, district, city, name, cardPath,
}) => (
  <div className='flex justify-between md:items-center'>
    <div className='flex flex-col md:flex-row items-baseline gap-2 pr-[40px] md:pr-[96px]'>
      <h2 className='text-[1.5rem] md:text-[1.75rem] lg:text-[2rem] leading-none md:leading-tight font-extrabold'>
        {type}
      </h2>
      <span className='text-sm md:text-lg'>
        { cardPath
          ? (
            <span>
              by <Link to={`/${city}/${district}/providers/${slugName}`} className='underline'>{name}</Link>
            </span>
          )
          : (
            <span>
              by {name}
            </span>
          ) }
      </span>
    </div>
  </div>
);

export default CardTitle;
