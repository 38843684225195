import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import separator from '../../assets/images/provider-card/breadcrumbsSeparator.svg';
import arrow from '../../assets/images/provider-card/link-arrow.svg';
import { CardPath } from '../../routes/types/routerTypes';

type CardBreadcrumbsProps = {
  city: string;
  district: string;
  provider: string;
  providerSlugName: string | null;
  program: string | null;
  cardPath: CardPath | null;
}

const CardBreadcrumbs: FC<CardBreadcrumbsProps> = ({
  cardPath = null,
  provider,
  providerSlugName,
  program = null,
  city,
  district,
}) => (
  <div className='flex items-center gap-[18px]'>
    {/* for provider */}
    {!program && !cardPath && (
    <>
      <Link
        to='../../providers/all'
        className='text-base md:text-lg text-primary-400 font-extrabold flex gap-2.5 items-center'
      >
        <img src={arrow} alt='arrow' className='md:hidden rotate-180' title='arrow' />
        All Providers
      </Link>
      <img src={separator} alt='separator' className='hidden md:block' title='separator' />
      <span
        className='hidden md:block md:text-lg text-primary-400 flex gap-2.5 items-center'
      >
        {provider}
      </span>
    </>
    )}

    {/* for playground */}
    {cardPath === CardPath.PLAYGROUND && !providerSlugName && (
    <>
      <Link
        to='../../providers/all'
        className='text-base md:text-lg text-primary-400 font-extrabold flex gap-2.5 items-center'
      >
        <img src={arrow} alt='arrow' className='md:hidden rotate-180' title='arrow' />
        All Providers
      </Link>
      <img src={separator} alt='separator' className='hidden md:block' title='separator' />
      <span
        className='hidden md:block text-base md:text-lg text-primary-400 flex gap-2.5 items-center'
      >
        Playground by {provider}
      </span>
    </>
    )}

    {/* for store */}
    {cardPath === CardPath.STORE && !providerSlugName && (
      <>
        <Link
          to='../../providers/all'
          className='text-base md:text-lg text-primary-400 font-extrabold flex gap-2.5 items-center'
        >
          <img src={arrow} alt='arrow' className='md:hidden rotate-180' title='arrow' />
          All Providers
        </Link>
        <img src={separator} alt='separator' className='hidden md:block' title='separator' />
        <span
          className='hidden md:block text-base md:text-lg text-primary-400 flex gap-2.5 items-center'
        >
          Store by {provider}
        </span>
      </>
    )}

    {/* for school program, class program  */}
    {program && cardPath
        && (
          <>
            <Link
              to='../../providers/all'
              className='hidden md:block text-base md:text-lg text-primary-400 font-extrabold flex gap-2.5 items-center'
            >
              All Providers
            </Link>
            <img src={separator} alt='separator' className='hidden md:block' title='separator' />
            <Link
              to={`/${city}/${district}/providers/${providerSlugName}`}
              className='text-base md:text-lg text-primary-400 font-extrabold flex gap-2.5 items-center'
            >
              <img src={arrow} alt='arrow' className='md:hidden rotate-180' title='arrow' />
              {provider}
            </Link>
            <img src={separator} alt='separator' className='hidden md:block' title='separator' />
            <span
              className='hidden text-base md:block md:text-lg text-primary-400 flex gap-2.5 items-center'
            >
              {program}
            </span>
          </>
        )}
  </div>
);

export default CardBreadcrumbs;
