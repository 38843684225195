import React, { FC } from 'react';

export interface FileListProps {
  files: File[]
}

const FileList: FC<FileListProps> = ({ files }) => (
  <ul>
    {files.map((file: File) => (
      <li key={`${file.name}_${file.lastModified}`}>
        <span>{file.name}</span>{' '}
        <span>({Math.round(file.size / 1000)}kb)</span>
      </li>
    ))}
  </ul>
);

export default FileList;
