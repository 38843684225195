import React, { FC } from 'react';
import { Link } from 'react-router-dom';

export interface NavListPropsType {
  className?: string;
  fontColor?: string;
  list: NavType[];
}

export interface NavType {
  name: string;
  link: string;
  isExternal?: boolean;
}

const NavList: FC<NavListPropsType> = ({
  className = '',
  list,
  fontColor = 'text-white',
}) => (
  <div className='grid grid-cols-2 md:grid-cols-[max-content_max-content_max-content] justify-center gap-y-0 lg:gap-x-[48px] flex-wrap'>
    { list.map((el: NavType, i) => (
      el.isExternal === true
        ? (
          <a key={i} title={el.name} href={el.link} className={`flex py-1 lg:py-2 px-2 flex-col ${className}`}>
            <span className={`text-base ${fontColor} opacity-80 hover:opacity-100`}>{el.name}</span>
          </a>
        )
        : (
          <Link title={el.name} key={i} to={el.link} className={`flex py-1 lg:py-2 px-2 flex-col ${className}`}>
            <span className={`text-base ${fontColor} opacity-80 hover:opacity-100`}>{el.name}</span>
          </Link>
        )
    ))}
  </div>
);

export default NavList;
