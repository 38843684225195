import React, { FC } from 'react';
import CardSocialList, { CardSocial } from './CardSocialList';

type CardContactPropsType = {
  phone: string,
  socials: CardSocial[]
}

const CardContact: FC<CardContactPropsType> = ({ phone, socials }) => (
  <div className='flex flex-col gap-2 md:gap-4'>
    <span className='text-sm'>Contacts:</span>
    <a href={`tel:${phone}`} title='phone number' className='text-xl md:text-[1.625rem] pb-2 md:pb-0'>{phone && phone !== '-' ? '+' : ''}{phone}</a>
    <CardSocialList cardSocials={socials} />
  </div>
);

export default CardContact;
