import React from 'react';
import MyRouter from 'routes/MyRoutes';

function App() {
  return (
    <div className='bg-primary-50 text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 flex flex-col flex-shrink-0 min-h-[100vh]'>
      <MyRouter />
    </div>
  );
}

export default App;
