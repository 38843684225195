import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import appStateSlice from './app/state/appReducer';
import allProvidersSlice from './modules/all-providers/state/AllProviderReducer';
import providerSlice from './modules/provider/state/providerReducer';
import schoolSlice from './modules/school/state/schoolReducer';
import storeSlice from './modules/store/state/storeReducer';
import playgroundSlice from './modules/playground/state/playgroundReducer';
import blogSlice from './modules/blog/state/blogReducer';
import articleSlice from './modules/article/state/articleReducer';

export const store = configureStore({
  reducer: {
    allProviders: allProvidersSlice,
    provider: providerSlice,
    school: schoolSlice,
    playground: playgroundSlice,
    store: storeSlice,
    appState: appStateSlice,
    blog: blogSlice,
    article: articleSlice,
  },
});

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

export type RootState = ReturnType<typeof store.getState>

// @ts-ignore
window.store = store;
