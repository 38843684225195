import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { providerAPI } from '../../../api/provider-api';
import { setAppError, setAppStatus } from '../../../app/state/appReducer';
import AppStatus from '../../../app/types/appStatusTypes';
import type { RootState } from '../../../store';
import { PlaygroundType } from '../types/playgroundTypes';

type PlaygroundStateType = {
  playground: PlaygroundType | Record<string, undefined>
  cache: {[key: string]: PlaygroundType}
}

const initialState: PlaygroundStateType = {
  playground: {},
  cache: {},
};

export const searchPlayground = createAsyncThunk(
  'playground',
  async (data: { district: string, name: string, city: string }, { dispatch, getState }) => {
    const { cache } = (getState() as RootState).playground as PlaygroundStateType;
    if (cache[data.name]) {
      return { statusCode: 200, playground: cache[data.name] };
    }
    try {
      dispatch(setAppStatus(AppStatus.LOADING));
      const response = await providerAPI.searchPlayground(data.name, data.district, data.city);
      dispatch(playgroundSlice.actions.addToCache(
        { name: data.name, data: response.data.playground },
      ));
      dispatch(setAppStatus(AppStatus.SUCCEEDED));
      return response.data;
    } catch (err: any) {
      dispatch(setAppStatus(AppStatus.NOT_FOUND));
      dispatch(setAppError('kindergarten not found'));
      return Promise.reject(err.message);
    }
  },
);

const playgroundSlice = createSlice({
  name: 'playground',
  initialState,
  reducers: {
    addToCache: (state, action: PayloadAction<{name: string, data: PlaygroundType}>) => {
      state.cache[action.payload.name] = { ...action.payload.data };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchPlayground.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          state.playground = action.payload.playground;
        }
      });
  },
});
export default playgroundSlice.reducer;
