import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import AppStatus from '../../app/types/appStatusTypes';
import { DetailItem } from '../../common/Card/CardDetailsList';
import CardTemplate from '../../common/Card/CardTemplate';
import LoadingProgramPage from '../../common/Loading/LoadingProgramPage';
import { CardPath } from '../../routes/types/routerTypes';
import { useAppDispatch, RootState } from '../../store';
import { searchClassProgram } from './state/schoolReducer';
import { SchoolParamsType, ClassProgramType } from './types/schoolTypes';

const ClassProgramCard = () => {
  const {
    provider, district, program, city,
  } = useParams<keyof SchoolParamsType>() as SchoolParamsType;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchClassProgram({
      program, district, city,
    }));
  }, []);

  const cardData: unknown = useSelector<RootState>((state) => state.school.class);
  const appStatus = useSelector<RootState>((state) => state.appState.status);

  assertClassData(cardData);

  const classProgramDetails: DetailItem[] = [
    { detail: 'Age:', content: `${cardData.age} years` },
    { detail: 'Language:', content: cardData.language },
    { detail: 'Schedule:', content: cardData.schedule },
    { detail: 'Working hours:', content: cardData.workingHours },
    { detail: 'Group size:', content: cardData.groupSize },
    { detail: 'Single lesson:', content: `${cardData.hasSingleLessons ? 'yes' : 'no'}` },
  ];

  if (appStatus === AppStatus.NOT_FOUND) {
    return <Navigate to='/404' />;
  }

  if (appStatus === AppStatus.LOADING) {
    return <LoadingProgramPage />;
  }

  return (
    <CardTemplate
      cardPath={CardPath.CLASS_PROGRAM}
      urlCity={city}
      urlDistrict={district}
      urlProviderName={provider}
      cardData={cardData}
      cardDetails={classProgramDetails}
    />
  );
};

export default ClassProgramCard;

function assertClassData(spaceData: unknown): asserts spaceData is ClassProgramType {
  if (typeof spaceData === 'object' && spaceData !== null) {
    return;
  }
  throw new Error('The space mock-data is not an object');
}
