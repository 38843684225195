import React, { FC, useState } from 'react';
import Popup from 'reactjs-popup';
import closeMenu from '../../assets/images/popup/close.svg';
import closeMenuMobile from '../../assets/images/popup/close-mobile.svg';
import noShareImage from '../../assets/images/popup/no-share.svg';
import Button from '../Button/Button';
import Input from '../Input/Input';
import NcImage from '../NcImage/NcImage';

const overlayStyle = { background: 'rgba(0,0,0,0.2)' };

type ShareButtonPropsType = {
  provider: string
  program?: string
  city?: string
  district?: string
  image: string | undefined
  isArticle?: boolean
}
const ShareButton: FC<ShareButtonPropsType> = ({
  image = noShareImage,
  district,
  city,
  program,
  provider,
  isArticle = false,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyClick = async () => {
    const result = await navigator.clipboard.writeText(window.location.href);
    setIsCopied(true);
    return result;
  };
  const handleClose = (close: () => void) => {
    setIsCopied(false);
    close();
  };

  const renderTrigger = () => (
    <div className='flow-root'>
      <div className='flex text-neutral-700 text-lg min-h-6 -mx-3 -my-1.5'>
        <span className='py-1.5 px-3 hover:text-primary-400 flex rounded-lg cursor-pointer items-center'>
          <span className='hidden md:block'>Share</span>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-[22px] w-[22px] ml-2.5'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={1.5}
              d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12'
            />
          </svg>
        </span>
      </div>
    </div>
  );

  const popupBody: any = (close: () => void) => (
        <div className='mx-4 p-6 pt-[64px] relative flex flex-col mx:py-10 md:py-[70px] items-center shadow-[0_4px_20px_rgba(0,0,0,0.15)] bg-primary-50 md:w-[680px] lg:w-[740px] h-fit z-[999] rounded-[20px] gap-8 md:gap-[25px]'>
          <button
            className='absolute right-[30px] md:right-10 top-[30px] md:top-10 w-fit h-fit'
            type='button'
            onClick={() => handleClose(close)}
          >
            <img
              src={closeMenu}
              alt='close'
              title='close'
              className='hidden md:block w-4 md:w-[21px] h-4 md:h-[21px]'
            />
            <img
              src={closeMenuMobile}
              alt='close'
              title='close'
              className='md:hidden w-4 md:w-[21px] h-4 md:h-[21px]'
            />
          </button>
          <h3 className='font-extrabold w-[195px] md:w-auto text-2xl'>{!isArticle ? 'Tell your friends about this space!' : 'Share this article!'}</h3>
          <div className='flex gap-4 md:gap-6 rounded-md w-full md:w-[420px] md:mb-2'>
            <div className='w-[70px] h-[70px]'>
              <NcImage
                src={image}
                className='rounded-md w-[70px] h-[70px] object-cover'
              />
            </div>
            <div className='flex flex-col text-sm justify-center opacity-60'>
              <span className='font-extrabold'>{provider}</span>
              <span>{program}</span>
              <span>
                {!isArticle ? `${city}, ${district}` : ''}
              </span>
            </div>
          </div>
          <div className='flex flex-col md:flex-row gap-2 w-full md:w-[420px]'>
            <Input
              type='text'
              placeholder='link to copy'
              className='w-full md:w-[270px] focus:border-neutral-200 text-lg'
              value={window.location.href}
              readOnly
            />
            <Button
              className={`text-lg ${isCopied ? 'bg-secondary-300 hover:shadow-none' : 'bg-primary-400'} w-full md:w-[140px] rounded-full text-white flex items-center justify-center hover:shadow-[0_4px_20px_rgba(0,0,0,0.15)]`}
              onClick={handleCopyClick}
              disabled={isCopied}
              sizeClass='px-0 py-3'
            >
              {isCopied ? 'Link copied!' : 'Copy link'}
            </Button>
          </div>
        </div>
  );

  return (
    <Popup
      trigger={renderTrigger()}
      modal
      nested
      lockScroll={false}
      closeOnEscape
      overlayStyle={overlayStyle}
    >
      {popupBody}
    </Popup>
  );
};

export default ShareButton;
