import React from 'react';

export interface LogoProps {
  img?: string;
  className?: string;
  link?: string;
}

const Logo: React.FC<LogoProps> = ({
  img,
  className,
  link = '/',
}) => (
  <a
    href={link}
    title='logo'
    className={`inline-block text-primary-400 focus:outline-none focus:ring-0 ${className}`}
  >
    {img ? (
      <img
        className='block'
        src={img}
        alt='Kidspace'
        title='Kidspace'
      />
    ) : (
      'Logo Here'
    )}
  </a>
);

export default Logo;
