import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';
import Button from '../../../shared/Button/Button';

export interface SectionAdsProps {
  className?: string;
  image?: string;
  title?: string;
}

const SectionAdsBot: FC<SectionAdsProps> = ({ className, image }) => (
  <section className={`${className} h-[400px] xl:col-span-6 md:col-span-2 hidden justify-evenly bg-white rounded-2xl`}>
    <div className='flex justify-start'>
      <NcImage
        containerClassName='flex md:w-[220px] lg:w-[320px]'
        src={image}
        alt='logo'
        className='object-fit'
      />
    </div>
    <div className='flex flex-col justify-center gap-6 grow-0 xl:w-[600px] lg:w-[520px] md:w-[400px]'>
      <h2 className='text-[32px]  leading-tight font-semibold'>Join our Bali-bot!</h2>
      <p className='text-2xl'>More than 350 kid-friendly places selected in Bali:
        schools, kindergartens, classes, shops,
        playgrounds and events.
        Chatbot is available in the Telegram messenger
      </p>
      <Button className='w-[310px] text-lg leading-tight bg-primary-400 rounded-full text-white flex items-center justify-center hover:shadow-[0_4px_20px_rgba(0,0,0,0.15)]'>
        <Link to='https://t.me/kidspaces_bot'>
          Go to bot!
        </Link>
      </Button>
    </div>
  </section>
);

export default SectionAdsBot;
