import axios from 'axios';
import { ArticlePromo } from '../modules/article/types/articleTypes';
import { ArticleType } from '../modules/blog/types/blogTypes';

export interface ArticleResponseType {
  statusCode: number;
  article: ArticleType;
}

export interface PromoArticlesResponseType {
  statusCode: number;
  articles: ArticlePromo[];
}

export interface AllArticlesResponseType {
  statusCode: number;
  articles: ArticleType[];
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
});

const blogAPI = {
  getAllArticles() {
    return instance.get<AllArticlesResponseType>('blogArticle');
  },
  searchArticle(name: string) {
    return instance.get<ArticleResponseType>('blogArticle/get.article', {
      params: { name },
    });
  },
  searchPromoArticles(name: string) {
    return instance.get<PromoArticlesResponseType>('blogArticle/get.promo.articles', {
      params: { name },
    });
  },
};

export default blogAPI;
