import React, { FC } from 'react';

type CardAvailableForSalePropsType = {
  saleOptions: string;
}
const CardAvailableForSale: FC<CardAvailableForSalePropsType> = ({ saleOptions }) => (
  <div className='flex justify-center items-center m-auto md:w-[495px] w-full'>
    <div className='flex flex-col gap-4'>
      <span className='text-sm'>Available for sale:</span>
      <span className='text-xl md:text-[1.625rem] md:leading-tight'>{saleOptions}</span>
    </div>
  </div>
);

export default CardAvailableForSale;
