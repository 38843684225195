import React, { FC } from 'react';

import linkedin from '../../assets/images/socials/linkedin.svg';
import telegram from '../../assets/images/socials/telegram.svg';
import whatsapp from '../../assets/images/socials/whatsapp.svg';
import instagram from '../../assets/images/socials/instagram.svg';
import facebook from '../../assets/images/socials/facebook.svg';
import donate from '../../assets/images/socials/donate.svg';

const socialList = [
  { name: 'donate', link: 'https://www.buymeacoffee.com/kidspace', image: donate },
  { name: 'whatsapp', link: 'http://wa.me/6281239015349', image: whatsapp },
  { name: 'instagram', link: 'https://www.instagram.com/kidspaceindonesia/', image: instagram },
  { name: 'facebook', link: 'https://www.facebook.com/kidspace.id/', image: facebook },
  { name: 'telegram', link: 'https://t.me/kidspaces_bot', image: telegram },
  { name: 'linkedin', link: 'https://www.linkedin.com/company/kidspace-indonesia', image: linkedin },
];

type SocialListPropsType = {
  className?: string;
}

const SocialList: FC<SocialListPropsType> = ({ className }) => (
  <div className='flex items-center md:gap-4 gap-2 flex-wrap'>
    {socialList.map((social) => (
      <a
        target='_blank'
        title={social.name}
        key={social.name}
        href={social.link}
        className={`w-8 h-8 rounded-full bg-secondary-300 hover:shadow-[0_4px_20px_rgba(0,0,0,0.15)] focus:outline-none flex items-center justify-center ${className}`}
        rel='noreferrer'
      >
        <span className='sr-only'>{social.name}</span>
        <img src={social.image} alt={social.name} title={social.name} className='w-4 w-4' />
      </a>
    ))}
  </div>
);

export default SocialList;
