import React, { FC } from 'react';
import clockImg from '../../../assets/images/blog-page/clock-small.svg';

export interface ArticleMetaProps {
  className?: string;
  date: string
  readingTime: string;
  size?: 'large' | 'normal';
}

const ArticleMeta: FC<ArticleMetaProps> = ({
  className = 'leading-none',
  date,
  readingTime,
  size = 'normal',
}) => (
  <div
    className={`nc-ArticleMeta gap-1 inline-flex items-center flex-wrap text-primary-700 ${
      size === 'normal' ? 'text-sm' : 'text-base'
    } ${className}`}
    data-nc-id='ArticleMeta'
  >
    <span className='text-primary-700 font-normal pr-2'>
      {date}
    </span>
    <img src={clockImg} alt='clock' title='clock' />
    <span className='text-primary-700 font-normal'>
      {readingTime}
      {' '}
      to read
    </span>
  </div>
);

export default ArticleMeta;
