import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import AppStatus from '../../app/types/appStatusTypes';
import CardBreadcrumbs from '../../common/Card/CardBreadcrumbs';
import CardCityDistrict from '../../common/Card/CardCityDistrict';
import CardContact from '../../common/Card/CardContacts';
import { DetailItem } from '../../common/Card/CardDetailsList';
import { CardSocial } from '../../common/Card/CardSocialList';
import LoadingProviderPage from '../../common/Loading/LoadingProviderPage';
import { CardPath } from '../../routes/types/routerTypes';
import NcImage from '../../shared/NcImage/NcImage';
import ShareButton from '../../shared/ShareButton/ShareButton';
import { useAppDispatch, RootState } from '../../store';
import ProviderDetailsList from './components/ProviderDetailsList';
import ProviderOptionList from './components/ProviderOptionList';
import ProviderRating from './components/ProviderRating';
import ProviderTitle from './components/ProviderTitle';
import providerNoImage from '../../assets/images/popup/no-share.svg';
import { searchProvider } from './state/providerReducer';
import { ProviderParamsType, ProviderType } from './types/providersTypes';

const SchoolProviderCard = () => {
  const { district, name, city } = useParams<keyof ProviderParamsType>() as ProviderParamsType;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchProvider({ district, name, city }));
  }, []);

  const cardData: unknown = useSelector<RootState>((state) => state.provider.provider);
  const appStatus = useSelector<RootState>((state) => state.appState.status);

  assertSchoolData(cardData);

  const providerDetails: DetailItem[] = [
    { detail: 'Languages:', content: cardData.language },
    { detail: 'Description:', content: cardData.description },
  ];

  const providerSocial: CardSocial[] = [
    { name: 'instagram', link: cardData.instagramLink },
    { name: 'whatsapp', link: `https://wa.me/${cardData.whatsappLink}` },
    { name: 'telegram', link: cardData.telegram },
    { name: 'siteLink', link: cardData.siteLink },
  ];

  const {
    schoolPrograms, classPrograms, stores, playgrounds,
  } = cardData;

  // eslint-disable-next-line max-len
  if (schoolPrograms?.length === 0 && classPrograms?.length === 0 && stores?.length === 0 && playgrounds?.length === 1) {
    return <Navigate to={`/${city}/${district}/providers/playground/${name}`} />;
  }

  // eslint-disable-next-line max-len
  if (schoolPrograms?.length === 0 && classPrograms?.length === 0 && stores?.length === 1 && playgrounds?.length === 0) {
    return <Navigate to={`/${city}/${district}/providers/store/${name}`} />;
  }

  if (appStatus === AppStatus.NOT_FOUND) {
    return <Navigate to='/404' />;
  }

  if (appStatus === AppStatus.LOADING) {
    return <LoadingProviderPage />;
  }

  return (
    <main className='container relative z-10 flex flex-col w-full gap-6 mb-20 grow'>
      <Helmet>
        <title>{`Kidspace: ${cardData.name}`}</title>
        <meta name='keywords' content={cardData.tags ? cardData.tags.join(' ') : ''} />
      </Helmet>
      <h1 className='visually-hidden'>{cardData.name}</h1>
      <CardBreadcrumbs
        city={cardData.city?.slugName}
        district={cardData.district?.slugName}
        provider={cardData.name}
        providerSlugName={null}
        program={null}
        cardPath={null}
      />
      <div className='w-full flex flex-col lg:p-8 lg:rounded-[20px] lg:border lg:border-neutral-200 gap-6 lg:gap-8'>
        <div className='flex gap-0 md:gap-8'>
          <div className='hidden md:block h-fit w-fit rounded-md'>
            <NcImage
              src={cardData?.providerPreviewImage || providerNoImage}
              className='rounded-md h-[64px] md:h-[104px] w-[64px] md:w-[104px] object-cover bg-secondary-300 max-w-none'
              containerClassName='h-fit w-fit'
            />
          </div>
          <div className='flex flex-col gap-4 w-full relative'>
            <div className='absolute right-2 top-2'>
              <ShareButton
                provider={cardData.name}
                district={cardData.district?.name}
                city={cardData.city?.name}
                image={cardData?.providerPreviewImage}
              />
            </div>
            <ProviderTitle
              name={cardData.name}
            />
            <div className='flex md:justify-start md:items-center items-start gap-8 md:gap-10'>
              <ProviderRating
                googleMapsRating={cardData.googleMapsRating}
                googleMapsID={cardData.googleMapsID}
              />
              <CardCityDistrict
                city={cardData.city?.name}
                district={cardData.district?.name}
                googleMapsID={cardData.googleMapsID}
                className='flex-col md:flex-row gap-0'
              />
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-6 md:flex-row md:gap-[50px] items-start justify-start'>
          <div className='w-fit'>
            <CardContact phone={cardData.whatsappLink} socials={providerSocial} />
          </div>
          <div className='w-fit md:grow'>
            <ProviderDetailsList details={providerDetails} />
          </div>
        </div>

        {cardData.schoolPrograms?.length !== 0
          ? (
            <ProviderOptionList
              options={cardData.schoolPrograms}
              optionsTitle='School Programs'
              city={city}
              district={district}
              provider={name}
              typeActivity={CardPath.SCHOOL_PROGRAM}
            />
          )
          : null}
        {cardData.classPrograms?.length !== 0
          ? (
            <ProviderOptionList
              options={cardData.classPrograms}
              optionsTitle='Class Programs'
              city={city}
              district={district}
              provider={name}
              typeActivity={CardPath.CLASS_PROGRAM}
            />
          )
          : null}
        {cardData.playgrounds?.length !== 0
          ? (
            <ProviderOptionList
              options={cardData.playgrounds}
              optionsTitle='Playgrounds'
              city={city}
              district={district}
              provider={name}
              typeActivity={CardPath.PLAYGROUND}
            />
          )
          : null}
        {cardData.stores?.length !== 0
          ? (
            <ProviderOptionList
              options={cardData.stores}
              optionsTitle='Stores'
              city={city}
              district={district}
              provider={name}
              typeActivity={CardPath.STORE}
            />
          )
          : null}
      </div>
    </main>
  );
};

export default SchoolProviderCard;

function assertSchoolData(spaceData: unknown): asserts spaceData is ProviderType {
  if (typeof spaceData === 'object' && spaceData !== null) {
    return;
  }
  throw new Error('The space mock-data is not an object');
}
