import React, { InputHTMLAttributes } from 'react';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = '',
      sizeClass = ' pl-8 pr-3 py-3',
      fontClass = 'text-lg font-normal',
      rounded = 'rounded-full',
      children,
      type = 'text',
      ...args
    },
    ref,
  ) => (
    <input
      ref={ref}
      type={type}
      className={`block w-full border-white focus:border-neutral-200 bg-white ${rounded} ${fontClass} ${sizeClass} ${className}`}
      {...args}
    />
  ),
);

export default Input;
