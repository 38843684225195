import React from 'react';

const PlaceIcon = () => (
  <svg
    className='w-full h-full'
    viewBox='0 0 151 161'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.6'>
      <path fillRule='evenodd' clipRule='evenodd' d='M78.9447 0.00195312H130.615C137.243 0.106339 150.5 4.41737 150.5 20.8266V59.1877V100.838V139.199C150.5 155.608 137.243 159.919 130.615 160.023H78.9447H72.0553H20.3852C13.7568 159.919 0.5 155.608 0.5 139.199V100.838V20.8263C0.5 4.41711 13.7568 0.106049 20.3852 0.00166321H72.0553L78.9447 0.00195312ZM130.615 10.0228C138.632 9.89757 140.636 17.1731 140.636 20.8266V100.838V139.199C140.636 142.852 138.632 150.128 130.615 150.003C122.598 149.877 92.8278 149.95 78.9447 150.003H72.0553C58.1722 149.95 28.4019 149.877 20.3852 150.003C12.3685 150.128 10.3643 142.852 10.3643 139.199V100.838V20.8263C10.3643 17.1729 12.3685 9.89728 20.3852 10.0225C28.4019 10.1478 58.1722 10.0747 72.0553 10.0225L78.9447 10.0228C92.8278 10.075 122.598 10.1481 130.615 10.0228Z' fill='white' />
      <path fillRule='evenodd' clipRule='evenodd' d='M20.542 115.311C20.542 116.876 21.4814 120.039 25.2393 120.164C28.9971 120.29 34.4251 120.217 36.6693 120.164L114.331 120.165C116.575 120.217 122.003 120.29 125.761 120.165C129.519 120.04 130.458 116.877 130.458 115.311V107.482V32.7948V24.966C130.458 23.4002 129.519 20.2374 125.761 20.1121C122.003 19.9869 116.575 20.0599 114.331 20.1121L36.6693 20.1121C34.4251 20.0599 28.9971 19.9869 25.2393 20.1121C21.4814 20.2374 20.542 23.4002 20.542 24.966V32.7948V107.482V115.311ZM25.2393 115.311H36.6693L102.744 115.311L109.477 115.311L114.331 115.311H125.761V107.482V101.932V94.4167V70.1385V32.7948V24.966H114.331H36.6693H25.2393V32.7948V65.6764V73.3486V92.4509V107.482V115.311Z' fill='white' />
      <path fillRule='evenodd' clipRule='evenodd' d='M20.542 115.311C20.542 116.876 21.4814 120.039 25.2393 120.164C28.9971 120.29 34.4251 120.217 36.6693 120.164L114.331 120.165C116.575 120.217 122.003 120.29 125.761 120.165C129.519 120.04 130.458 116.877 130.458 115.311V107.482V32.7948V24.966C130.458 23.4002 129.519 20.2374 125.761 20.1121C122.003 19.9869 116.575 20.0599 114.331 20.1121L36.6693 20.1121C34.4251 20.0599 28.9971 19.9869 25.2393 20.1121C21.4814 20.2374 20.542 23.4002 20.542 24.966V32.7948V107.482V115.311ZM25.2393 115.311H36.6693L102.744 115.311L109.477 115.311L114.331 115.311H125.761V107.482V101.932V94.4167V70.1385V32.7948V24.966H114.331H36.6693H25.2393V32.7948V65.6764V73.3486V92.4509V107.482V115.311Z' fill='white' />
      <path d='M25.2393 73.3486L45.281 50.3319L102.744 115.311L109.477 115.311L92.1359 95.6693L88.68 91.7549L48.0994 45.7912C47.2643 44.8517 45.0618 43.5365 42.9324 45.7912C40.803 48.0459 30.2497 59.9874 25.2393 65.6764V73.3486Z' fill='white' />
      <path d='M25.2393 73.3486L45.281 50.3319L102.744 115.311L109.477 115.311L92.1359 95.6693L88.68 91.7549L48.0994 45.7912C47.2643 44.8517 45.0618 43.5365 42.9324 45.7912C40.803 48.0459 30.2497 59.9874 25.2393 65.6764V73.3486Z' fill='white' />
      <path d='M102.118 76.567L88.68 91.7549L92.1359 95.6693L105.876 80.3248L125.761 101.932V94.4167C121.273 89.5628 111.607 79.1035 108.851 76.0972C106.095 73.091 103.214 75.1578 102.118 76.567Z' fill='white' />
      <path d='M102.118 76.567L88.68 91.7549L92.1359 95.6693L105.876 80.3248L125.761 101.932V94.4167C121.273 89.5628 111.607 79.1035 108.851 76.0972C106.095 73.091 103.214 75.1578 102.118 76.567Z' fill='white' />
      <path fillRule='evenodd' clipRule='evenodd' d='M95.8559 65.45C104.244 65.45 111.044 58.6502 111.044 50.2621C111.044 41.8741 104.244 35.0742 95.8559 35.0742C87.4678 35.0742 80.668 41.8741 80.668 50.2621C80.668 58.6502 87.4678 65.45 95.8559 65.45ZM95.6992 60.1269C101.234 60.1269 105.72 55.6404 105.72 50.106C105.72 44.5716 101.234 40.0851 95.6992 40.0851C90.1649 40.0851 85.6784 44.5716 85.6784 50.106C85.6784 55.6404 90.1649 60.1269 95.6992 60.1269Z' fill='white' />
    </g>
  </svg>
);

export default PlaceIcon;
