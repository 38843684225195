import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import logoImage from '../../assets/images/logos/logo-header.png';
import openMenu from '../../assets/images/header/open-menu.svg';
import closeMenu from '../../assets/images/header/close-menu.svg';
import Logo from '../../shared/Logo/Logo';
import NavList, { NavType } from '../../shared/NavList/NavList';
import SocialList from '../../shared/SocialList/SocialList';
import HeaderMobileNavList from './HeaderMobileNavList';

const blogHeaderNavLinks: NavType[] = [
  { name: 'About', link: 'https://www.kidspace.id/#about', isExternal: true },
  { name: 'Partnership', link: 'https://www.kidspace.id/#business_model', isExternal: true },
  { name: 'All Providers', link: '/providers/all' },
];

const providerHeaderNavLinks: NavType[] = [
  { name: 'About', link: 'https://www.kidspace.id/#about', isExternal: true },
  { name: 'Partnership', link: 'https://www.kidspace.id/#business_model', isExternal: true },
  { name: 'Blog', link: '/blog' },
];

const SiteHeader = () => {
  const location = useLocation();
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);
  const [isBlog, setIsBlog] = useState(false);

  useEffect(() => {
    if (width > 1280) {
      setIsOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (location.pathname.startsWith('/blog')) {
      setIsBlog(true);
    } else {
      setIsBlog(false);
    }
  }, [location]);

  if (location.pathname === '/404') {
    return null;
  }

  return (
    <div className='top-0 w-full left-0 right-0'>
      <div className='container relative pt-8 md:pt-7 pb-6 md:pb-8 relative flex justify-between items-center'>
        <div className='flex w-[93px] h-[93px] justify-start relative'>
          <Logo
            link={isBlog ? '/blog' : '/providers/all'}
            img={logoImage}
            className='hover:shadow-[0_4px_20px_rgba(0,0,0,0.15)] rounded-full'
          />
          {isBlog && <label className='text-sm font-extrabold absolute text-primary-400 left-full'>Blog</label>}
        </div>
        <div
          className='hidden xl:block absolute translate-x-[-50%] left-[50%] grow flex justify-center'
        >
          <NavList
            list={isBlog ? blogHeaderNavLinks : providerHeaderNavLinks}
            fontColor='text-primary-400'
          />
        </div>
        <div className='flex items-center font-bold justify-end gap-6'>
          <div className='hidden md:block'>
            <SocialList />
          </div>
          <button
            className='w-[48] h-[48] flex justify-center items-center xl:hidden z-10'
            type='button'
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen
              ? <img src={closeMenu} alt='close menu' title='close menu' />
              : <img src={openMenu} alt='open menu' title='open menu' />}
          </button>
        </div>
      </div>
      <HeaderMobileNavList
        isOpened={isOpen}
        list={isBlog ? blogHeaderNavLinks : providerHeaderNavLinks}
      />
    </div>
  );
};

export default SiteHeader;
