import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { providerAPI } from '../../../api/provider-api';
import { setAppError, setAppStatus } from '../../../app/state/appReducer';
import AppStatus from '../../../app/types/appStatusTypes';
import type { RootState } from '../../../store';
import {
  SchoolProgramType,
  ClassProgramType,
} from '../types/schoolTypes';

type SchoolStateType = {
  program: SchoolProgramType | Record<string, undefined>
  class: ClassProgramType | Record<string, undefined>
  cache: {[key: string]: SchoolProgramType | ClassProgramType}
}

const initialState: SchoolStateType = {
  program: {},
  class: {},
  cache: {},
};

export const searchSchoolProgram = createAsyncThunk(
  'provider-card/searchSchoolProgram',
  async (data: { district: string, program: string, city: string }, { dispatch, getState }) => {
    const { cache } = (getState() as RootState).school as SchoolStateType;
    if (cache[data.program]) {
      return { statusCode: 200, schoolProgram: cache[data.program] };
    }
    try {
      dispatch(setAppStatus(AppStatus.LOADING));
      // eslint-disable-next-line max-len
      const response = await providerAPI.searchSchoolProgram(data.program, data.district, data.city);
      dispatch(schoolSlice.actions.addToCache(
        { name: data.program, data: response.data.schoolProgram },
      ));
      dispatch(setAppStatus(AppStatus.SUCCEEDED));
      return response.data;
    } catch (err: any) {
      dispatch(setAppStatus(AppStatus.NOT_FOUND));
      dispatch(setAppError('school program not found'));
      return Promise.reject(err.message);
    }
  },
);

export const searchClassProgram = createAsyncThunk(
  'provider-card/searchClassProgram',
  async (data: {district: string, program: string, city: string }, { dispatch, getState }) => {
    const { cache } = (getState() as RootState).school as SchoolStateType;
    if (cache[data.program]) {
      return { statusCode: 200, classProgram: cache[data.program] };
    }
    try {
      dispatch(setAppStatus(AppStatus.LOADING));
      const response = await providerAPI.searchClassProgram(data.program, data.district, data.city);
      dispatch(schoolSlice.actions.addToCache(
        { name: data.program, data: response.data.classProgram },
      ));
      dispatch(setAppStatus(AppStatus.SUCCEEDED));
      return response.data;
    } catch (err: any) {
      dispatch(setAppStatus(AppStatus.NOT_FOUND));
      dispatch(setAppError('class program not found'));
      return Promise.reject(err.message);
    }
  },
);

const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    // eslint-disable-next-line max-len
    addToCache: (state, action: PayloadAction<{name: string, data: ClassProgramType | SchoolProgramType}>) => {
      state.cache[action.payload.name] = { ...action.payload.data };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchSchoolProgram.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          state.program = action.payload.schoolProgram as SchoolProgramType;
        }
      })
      .addCase(searchClassProgram.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          state.class = action.payload.classProgram as ClassProgramType;
        }
      });
  },
});

export default schoolSlice.reducer;
