import React, {
  FC,
  ImgHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import checkInViewIntersectionObserver from 'utils/isInViewPortIntersectionObserver';
import PlaceIcon from './PlaceIcon';

export interface NcImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  containerClassName?: string;
  customLoadingImage?: string;
}

const NcImage: FC<NcImageProps> = ({
  containerClassName = '',
  customLoadingImage = '',
  alt = 'nc-imgs',
  src = '',
  className = 'object-cover w-full h-full',
  ...args
}) => {
  let isMounted = false;
  const _containerRef = useRef(null);
  let _imageEl: HTMLImageElement | null = null;

  // eslint-disable-next-line camelcase
  const [__src, set__src] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);

  // eslint-disable-next-line require-await
  const _initActions = async () => {
    // set__src(placeholderImage);
    _checkInViewPort();
  };

  const _checkInViewPort = () => {
    if (!_containerRef.current) return;
    checkInViewIntersectionObserver({
      target: _containerRef.current,
      options: {
        root: null,
        rootMargin: '0%',
        threshold: 0,
      },
      freezeOnceVisible: true,
      callback: _imageOnViewPort,
    });
  };

  const _imageOnViewPort = () => {
    if (!src) {
      _handleImageLoaded();
      return true;
    }
    _imageEl = new Image();
    if (_imageEl) {
      _imageEl.src = src;
      _imageEl.addEventListener('load', _handleImageLoaded);
    }
    return true;
  };

  const _handleImageLoaded = () => {
    if (!isMounted) return;
    setImageLoaded(true);
    set__src(src);
  };

  useEffect(() => {
    isMounted = true;
    _initActions();
    return () => {
      isMounted = false;
    };
  }, [src]);

  const renderLoadingPlaceholder = () => (
    <div
      className={`flex items-center justify-center text-primary-400 ${className}`}
    >
      {customLoadingImage
        ? (
          <div className='h-2/5 max-w-[40%]'>
            <img src={customLoadingImage} alt={alt} title={alt} className='min-h-[500px] w-full' />)
          </div>
        )
        : (
          <div className='h-2/5 max-w-[40%]'>
            <PlaceIcon />
          </div>
        )}
    </div>
  );

  return (
    <div
      className={`nc-NcImage ${containerClassName}`}
      data-nc-id='NcImage'
      ref={_containerRef}
    >
      {__src && imageLoaded ? (
        <img src={__src} className={className} alt={alt} title={alt} {...args} />
      ) : (
        renderLoadingPlaceholder()
      )}
    </div>
  );
};

export default NcImage;
