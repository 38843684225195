import {
  createAsyncThunk, createSlice, PayloadAction, Reducer,
} from '@reduxjs/toolkit';
import { providerAPI } from '../../../api/provider-api';
import { setAppError, setAppStatus } from '../../../app/state/appReducer';
import AppStatus from '../../../app/types/appStatusTypes';
import type { RootState } from '../../../store';
import { ProviderType } from '../types/providersTypes';

const initialState: ProviderStateType = {
  provider: {},
  cache: {},
};

export type ProviderStateType = {
  provider: ProviderType | Record<string, undefined>
  cache: {[key: string]: ProviderType}
}

export const searchProvider = createAsyncThunk(
  'provider-card/searchProvider',
  async (data: { name: string, district: string, city: string }, { dispatch, getState }) => {
    const { cache } = (getState() as RootState).provider as ProviderStateType;
    if (cache[data.name]) {
      return { statusCode: 200, provider: cache[data.name] };
    }
    try {
      dispatch(setAppStatus(AppStatus.LOADING));
      const response = await providerAPI.searchProvider(data.name, data.district, data.city);
      dispatch(providerSlice.actions.addToCache({ name: data.name, data: response.data.provider }));
      dispatch(setAppStatus(AppStatus.SUCCEEDED));
      return response.data;
    } catch (err: any) {
      dispatch(setAppStatus(AppStatus.NOT_FOUND));
      dispatch(setAppError('provider-card not found'));
      return Promise.reject(err.message);
    }
  },
);

const providerSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    addToCache: (state, action: PayloadAction<{name: string, data: ProviderType}>) => {
      state.cache[action.payload.name] = { ...action.payload.data };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchProvider.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          state.provider = action.payload.provider;
        }
      });
  },
});

export default providerSlice.reducer as Reducer<typeof initialState>;
