import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { CardPath } from '../../../routes/types/routerTypes';
import NcImage from '../../../shared/NcImage/NcImage';
import { ProgramShort } from '../types/providersTypes';

type ProviderOptionListPropsType = {
  options: ProgramShort[] | [];
  optionsTitle: string;
  typeActivity: CardPath;
  provider: string;
  city: string;
  district: string;
}

const ProviderOptionList:FC<ProviderOptionListPropsType> = ({
  options,
  optionsTitle,
  provider,
  typeActivity,
  district,
  city,
}) => (
  <div className='flex flex-col space-y-6'>
    <span className='font-extrabold text-base md:text-lg'>
      {optionsTitle}
    </span>
    {options?.map((opt, i) => (
      <Link
        key={i}
        className='flex justify-between space-x-2 md:space-x-5'
        to={`/${city}/${district}/providers/${provider}${typeActivity}/${opt.slugName}`}
      >
        <div className='relative h-fit w-fit'>
          <NcImage
            src={opt.image || ''}
            className='rounded-md h-[60px] md:h-[115px] w-[94px] md:w-[180px] object-cover object-center max-w-none bg-secondary-300'
            containerClassName=''
          />
          <div className='absolute cursor-pointer rounded inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity' />
        </div>
        <div className='flex md:flex-col justify-between w-full md:gap-2 md:justify-start'>
          {/* type and price container */}
          <div className='flex flex-col gap-1 md:flex-row md:justify-between md:items-center'>
            <div className='font-extrabold text-lg md:text-2xl'>
              {opt.type}
            </div>
            <div className='font-extrabold text-base md:text-lg'>
              {opt.price.substring(0, opt.price.indexOf('idr') + 3)}
            </div>
          </div>
          {/* age and language container */}
          <div className='flex flex-col gap-1'>
            <div className='text-base md:text-lg flex items-center h-[28px] md:h-auto gap-1 justify-end md:justify-start'>
              {opt.age}
              <span className='hidden md:block'>years</span>
              <span className='md:hidden'>y.o.</span>
            </div>
            <div className='text-base md:text-lg flex justify-end md:justify-start'>
              <span className='hidden md:block'>{opt.language}</span>
              <span className='md:hidden'>{opt.language}</span>
            </div>
          </div>
        </div>
      </Link>
    ))}
  </div>
);

export default ProviderOptionList;
