import { ComponentType } from 'react';

export interface LocationStates {
  '/'?: object;
  '/#'?: object;
  //
  '/:city/:district/providers/:provider/school-program/:program'?: object;
  '/:city/:district/providers/:provider/class-program/:program'?: object;
  '/:city/:district/providers/:provider/playground/:program'?: object;
  '/:city/:district/providers/:provider/store/:program'?: object;
  '/:city/:district/providers/:name'?: object;
  '/providers/all'?: object;
  '/:city/:district/providers/playground/:name'?: object;
  '/:city/:district/providers/store/:name'?: object;
  //
  '/blog'?: object;
  '/blog/:name'?: object;
  '/blog-single'?: object;
  // temp
  '/upload'?: object;
  '/terms'?: object;
  '/privacy'?: object;
}

export type PathName = keyof LocationStates;

export interface Page {
  path: PathName;
  exact?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  component: ComponentType<Object>;
}

// eslint-disable-next-line no-shadow
export enum CardPath {
  SCHOOL_PROGRAM = '/school-program',
  CLASS_PROGRAM = '/class-program',
  PLAYGROUND = '/playground',
  STORE = '/store',
}
