import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { providerAPI } from '../../../api/provider-api';
import { setAppError, setAppStatus } from '../../../app/state/appReducer';
import AppStatus from '../../../app/types/appStatusTypes';
import type { RootState } from '../../../store';
import { StoreType } from '../types/storeTypes';

type StoreStateType = {
  store: StoreType | Record<string, undefined>
  cache: {[key: string]: StoreType}
}

const initialState: StoreStateType = {
  store: {},
  cache: {},
};

export const searchStore = createAsyncThunk(
  'store',
  async (data: { name: string, district: string, city: string }, { dispatch, getState }) => {
    const { cache } = (getState() as RootState).store as StoreStateType;
    if (cache[data.name]) {
      return { statusCode: 200, store: cache[data.name] };
    }
    try {
      dispatch(setAppStatus(AppStatus.LOADING));
      const response = await providerAPI.searchStore(data.name, data.district, data.city);
      dispatch(setAppStatus(AppStatus.SUCCEEDED));
      dispatch(storeSlice.actions.addToCache({ name: data.name, data: response.data.store }));
      return response.data;
    } catch (err: any) {
      dispatch(setAppStatus(AppStatus.NOT_FOUND));
      dispatch(setAppError('store not found'));
      return Promise.reject(err.message);
    }
  },
);

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    addToCache: (state, action: PayloadAction<{name: string, data: StoreType}>) => {
      state.cache[action.payload.name] = { ...action.payload.data };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchStore.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          state.store = action.payload.store;
        }
      });
  },
});

export default storeSlice.reducer;
