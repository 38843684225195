import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ArticleType } from 'modules/blog/types/blogTypes';
import blogAPI from '../../../api/blog-api';
import { setAppStatus, setAppError } from '../../../app/state/appReducer';
import AppStatus from '../../../app/types/appStatusTypes';
import type { RootState } from '../../../store';
import { providersPromoMock } from '../mock-data/articleMockData';
import { ProviderPromo, ArticlePromo } from '../types/articleTypes';

export type ArticleStateType = {
  article: ArticleType | Record<string, undefined>
  providersPromo: ProviderPromo[]
  articlesPromo: ArticlePromo[]
}

const initialState: ArticleStateType = {
  article: {},
  providersPromo: [],
  articlesPromo: [],
};

export const searchArticle = createAsyncThunk(
  'articles/searchArticle',
  async (slugName: string, { dispatch, getState }) => {
    const allArticles = (getState() as RootState).blog.allArticles as ArticleType[];
    const article = allArticles.find((art) => art.slugName === slugName);
    if (article) {
      return { statusCode: 200, article };
    }
    try {
      dispatch(setAppStatus(AppStatus.LOADING));
      const response = await blogAPI.searchArticle(slugName);
      dispatch(setAppStatus(AppStatus.SUCCEEDED));
      return response.data;
    } catch (err: any) {
      dispatch(setAppError('articles not found'));
      return Promise.reject(err.message);
    }
  },
);

export const getPromoProviders = createAsyncThunk(
  'articles/getPromoProviders',
  async (data, { dispatch }) => {
    try {
      dispatch(setAppStatus(AppStatus.LOADING));
      const response = await providersPromoMock;
      dispatch(setAppStatus(AppStatus.SUCCEEDED));
      return response;
    } catch (err: any) {
      dispatch(setAppError('articles not found'));
      return Promise.reject(err.message);
    }
  },
);

export const getPromoArticles = createAsyncThunk(
  'articles/getPromoArticles',
  async (slugName: string, { dispatch, getState }) => {
    const { articlesPromo } = (getState() as RootState).article as ArticleStateType;
    const articles = articlesPromo.filter((art) => art.slugName !== slugName);
    if (articles.length === 3) {
      return { statusCode: 200, articles };
    }
    try {
      dispatch(setAppStatus(AppStatus.LOADING));
      const response = await blogAPI.searchPromoArticles(slugName);
      dispatch(setAppStatus(AppStatus.SUCCEEDED));
      return response.data;
    } catch (err: any) {
      dispatch(setAppError('articles not found'));
      return Promise.reject(err.message);
    }
  },
);

const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchArticle.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          state.article = action.payload.article;
        }
      })
      .addCase(getPromoProviders.fulfilled, (state, action) => {
        if (action.payload) {
          state.providersPromo = action.payload;
        }
      })
      .addCase(getPromoArticles.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          state.articlesPromo = action.payload.articles;
        }
      });
  },
});

export default articleSlice.reducer;
