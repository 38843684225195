import React, { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import FileList from './FileList';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UploadZoneProps {
  onChange: any;
}

const UploadZone: FC<UploadZoneProps> = ({ onChange }) => {
  const addFiles: File[] = [];
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      addFiles.push(file);
    });
    onChange(addFiles);
  }, []);
  const {
    getRootProps, getInputProps, acceptedFiles, isDragActive,
  } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className='mt-1 flex flex-col justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md'>
      <div className='space-y-1 text-center'>
        <svg
          className='mx-auto h-12 w-12 text-neutral-400'
          stroke='currentColor'
          fill='none'
          viewBox='0 0 48 48'
          aria-hidden='true'
        >
          <path
            d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <div className='flex flex-col text-sm text-neutral-6000 dark:text-neutral-300'>
          <div>
            <input {...getInputProps()} />
            {
              isDragActive
                ? <p>Drop the files here ...</p>
                : <p>Drag 'n' drop some files here, or click to select files</p>
            }
          </div>
        </div>
        <p className='text-xs text-neutral-500 dark:text-neutral-400'>
          PNG, JPG, GIF up to 10MB
        </p>
      </div>
      <div className='mt-10 space-y-10 text-center'>
        <FileList files={acceptedFiles} />
      </div>
    </div>
  );
};

export default UploadZone;
