import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import twFocusClass from 'utils/twFocusClass';

export interface ButtonCloseProps {
  className?: string;
  onClick?: () => void;
}

const ButtonClose: React.FC<ButtonCloseProps> = ({
  className = '',
  onClick = () => {},
}) => (
  <button
    className={
        `w-8 h-8 flex items-center justify-center rounded-full bg-secondary-300 text-white hover:bg-primary-400 hover:border-white ${className} ${
          twFocusClass()}`
      }
    onClick={onClick}
  >
    <span className='sr-only'>Close</span>
    <XMarkIcon className='w-5 h-5' />
  </button>
);

export default ButtonClose;
