import React, { FC } from 'react';
import Button from 'shared/Button/Button';
import UploadPath from './UploadPath';
import UploadZone from './UploadZone';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PageProps {}

const UploadPage: FC<PageProps> = () => {
  const state = {
    path: '',
    files: [],
  };

  function handlePath(p: any) {
    state.path = `/:${p.city}/:${p.district}/:${p.providerSlugName}/${p.programType}/:${p.programSlugName}/`;
  }

  function handleFiles(p: any) {
    state.files = p;
  }

  function callService() {
    if (state.files.length > 0 && state.path !== '') {
      console.log(state);
    }
  }

  return (
    <div
      className='container mb-10'
    >
      <div>
        <h2 className='text-2xl font-semibold'>Pictures of the place</h2>
        <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
          A few beautiful photos will help customers have more sympathy for
          your property.
        </span>
      </div>
      {/* FORM */}
      <div className='py-5'>
        <div>
          <UploadPath onChange={handlePath} />
          <div className='mt-5'>
            <UploadZone onChange={handleFiles} />
            <div className='mt-10 space-y-1 text-center'>
              <Button onClick={callService} className='text-lg bg-primary-400 rounded-full text-white flex items-center justify-center hover:shadow-[0_4px_20px_rgba(0,0,0,0.15)]' type='submit'>
                Save the file to S3 Bucket
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default UploadPage;
