import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useWindowSize } from 'react-use';

const LoadingProviderPage = () => {
  const baseColor = '#E8E3E1';
  const highlightColor = '#F4A382';
  const { width } = useWindowSize();
  let textHeight = 18;
  let widthFactor = 1;
  if (width > 768) {
    textHeight = 24;
  }
  if (width > 1200) {
    widthFactor = 1.5;
  }

  if (width < 768) {
    return (
      <SkeletonTheme
        baseColor={baseColor}
        highlightColor={highlightColor}
        duration={2}
        borderRadius={8}
      >
        <main className='container flex flex-col w-full mb-20 grow'>
          <div className='w-full flex flex-col gap-5'>
            <div className='flex flex-col gap-2 items-start justify-start'>
              <Skeleton height={textHeight} width={220} />
              <Skeleton height={textHeight} width={220} />
              <Skeleton height={textHeight} width={220} />
              <Skeleton height={textHeight} width={220} />
              <div className='flex items-center gap-2'>
                <Skeleton width={32} height={32} borderRadius={16} />
                <Skeleton width={32} height={32} borderRadius={16} />
                <Skeleton width={32} height={32} borderRadius={16} />
                <Skeleton width={32} height={32} borderRadius={16} />
              </div>
              <div className='w-fit md:grow'>
                <ul className='flex flex-col gap-2'>
                  <li className='flex justify-start items-baseline gap-3'>
                    <Skeleton height={textHeight} width={94} />
                    <Skeleton height={textHeight} width={220} />
                  </li>
                  <li className='flex justify-start items-baseline gap-3'>
                    <Skeleton height={textHeight} width={94} />
                    <div className='flex flex-col gap-2'>
                      <Skeleton height={textHeight} width={220} />
                      <Skeleton height={textHeight} width={220} />
                      <Skeleton height={textHeight} width={220} />
                      <Skeleton height={textHeight} width={220} />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
              <div className='flex gap-3'>
                <Skeleton height={60} width={94} />
                  <div className='flex flex-col gap-2'>
                    <Skeleton height={textHeight} width={220} />
                    <Skeleton height={textHeight} width={220} />
                  </div>
              </div>
          </div>
        </main>
      </SkeletonTheme>
    );
  }

  return (
    <SkeletonTheme
      baseColor={baseColor}
      highlightColor={highlightColor}
      duration={2}
      borderRadius={12}
    >
      <main className='container flex flex-col w-full gap-6 mb-20 grow'>
        <div className='flex items-center'>
          <Skeleton height={textHeight} width={300} />
        </div>
        <div className='w-full flex flex-col lg:p-8 lg:rounded-[20px] lg:border lg:border-neutral-200 gap-6 lg:gap-8'>
          <div className='flex gap-[30px]'>
            <Skeleton width={104} height={104} />
            <div className='flex flex-col gap-3 w-full relative'>
                <Skeleton height={40} width={300 * widthFactor} />
                <Skeleton height={textHeight} width={300 * widthFactor} />
            </div>
          </div>

          <div className='flex flex-row gap-[50px]'>
            <div className='w-fit'>
              <div className='flex flex-col gap-3'>
                <Skeleton height={textHeight} width={190} />
                <Skeleton height={textHeight} width={190} />
                <div className='flex items-center space-x-2'>
                  <Skeleton width={32} height={32} borderRadius={16} />
                  <Skeleton width={32} height={32} borderRadius={16} />
                  <Skeleton width={32} height={32} borderRadius={16} />
                  <Skeleton width={32} height={32} borderRadius={16} />
                </div>
              </div>
            </div>
            <div className='w-fit md:grow'>
              <ul className='flex flex-col gap-3'>
                <li className='flex justify-start items-baseline gap-5'>
                  <Skeleton height={textHeight} width={100 * widthFactor} />
                  <Skeleton height={textHeight} width={320 * widthFactor} />
                </li>
                <li className='flex justify-start items-baseline gap-5'>
                  <Skeleton height={textHeight} width={100 * widthFactor} />
                  <div className='flex flex-col gap-3'>
                    <Skeleton height={textHeight} width={320 * widthFactor} />
                    <Skeleton height={textHeight} width={320 * widthFactor} />
                    <Skeleton height={textHeight} width={320 * widthFactor} />
                    <Skeleton height={textHeight} width={320 * widthFactor} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='flex flex-col gap-6'>
            <Skeleton height={textHeight} width={250 * widthFactor} />
            <div className='flex justify-between gap-[50px]'>
              <Skeleton height={115} width={180} />
              <div className='flex md:flex-col justify-between w-full md:gap-2 md:justify-start'>
                <div className='flex flex-col gap-3'>
                  <Skeleton height={textHeight} width={150 * widthFactor} />
                  <Skeleton height={textHeight} width={150 * widthFactor} />
                  <Skeleton height={textHeight} width={150 * widthFactor} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </SkeletonTheme>
  );
};

export default LoadingProviderPage;
