// eslint-disable-next-line import/named
import {
  createAsyncThunk, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import blogAPI from '../../../api/blog-api';
import { setAppError, setAppStatus } from '../../../app/state/appReducer';
import AppStatus from '../../../app/types/appStatusTypes';
import type { RootState } from '../../../store';
import { ArticleType } from '../types/blogTypes';

type BlogStateType = {
  allArticles: ArticleType[]
  displayedArticlesCount: number
}

const initialState: BlogStateType = {
  allArticles: [],
  displayedArticlesCount: 10,
};

export const getAllArticles = createAsyncThunk(
  'blog/getAllArticles',
  async (data, { dispatch, getState }) => {
    const { allArticles } = (getState() as RootState).blog as BlogStateType;
    if (allArticles.length > 0) {
      return { statusCode: 200, articles: allArticles };
    }
    try {
      dispatch(setAppStatus(AppStatus.LOADING));
      const response = await blogAPI.getAllArticles();
      dispatch(setAppStatus(AppStatus.SUCCEEDED));
      return response.data;
    } catch (err: any) {
      dispatch(setAppError('articles not found'));
      return Promise.reject(err.message);
    }
  },
);

const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    incrementDisplayedArticles: (state, action: PayloadAction<number>) => {
      state.displayedArticlesCount += action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllArticles.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          state.allArticles = action.payload.articles;
        }
      });
  },
});

export const { incrementDisplayedArticles } = blogSlice.actions;
export default blogSlice.reducer;
