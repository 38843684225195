import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import triangleImage from 'assets/images/blog-page/card-bot-triangle.svg';
import Button from '../../shared/Button/Button';
import { useAppDispatch, RootState } from '../../store';
import ArticlePreview from './components/ArticlePreview';
import SectionAdsBot from './components/SectionAdsBot';
import {
  getAllArticles,
  // eslint-disable-next-line import/named
  incrementDisplayedArticles,
} from './state/blogReducer';
import { ArticleType } from './types/blogTypes';

const BlogPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllArticles());
  }, []);

  const allArticles: unknown = useSelector<RootState>((state) => state.blog.allArticles);
  // eslint-disable-next-line max-len
  const displayedArticlesCount = useSelector<RootState>((state) => state.blog.displayedArticlesCount) as number;

  assertArticles(allArticles);

  // const ArticlesByCount = allArticles.slice(0, displayedArticlesCount);

  const moreButtonHandler = () => dispatch(incrementDisplayedArticles(6));

  return (
    <main className='nc-BlogPage flex w-full grow'>
      <Helmet>
        <title>Kidspace: Blog page</title>
      </Helmet>
      <h1 className='visually-hidden'>Blog page</h1>
      <div className='flex flex-col container gap-8 mb-[50px] md:mb-20'>
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-x-4 gap-y-4 md:gap-y-8'>
          {allArticles.map((article, index) => {
            // show SectionAdsBot at desktop page after 5th article
            if (index === 4) {
              return (
                <>
                  <ArticlePreview article={article} key={index} index={index} />
                  <SectionAdsBot image={triangleImage} className='xl:flex' />
                </>
              );
            }
            // show SectionAdsBot at tablet page after 4th article
            if (index === 3) {
              return (
                <>
                  <ArticlePreview article={article} key={index} index={index} />
                  <SectionAdsBot image={triangleImage} className='md:flex xl:hidden' />
                </>
              );
            }
            return <ArticlePreview article={article} key={index} index={index} />;
          })}
        </div>
        { allArticles.length > displayedArticlesCount
          ? (
            <div className='flex items-center justify-center'>
              <Button
                className='font-normal w-full md:w-[310px] text-lg leading-tight bg-transparent text-primary-400 hover:bg-primary-400 hover:text-white hover:shadow-[0_4px_20px_rgba(0,0,0,0.15)] border border-primary-400'
                onClick={moreButtonHandler}
              >
                More
              </Button>
            </div>
          )
          : null}
      </div>
    </main>
  );
};

export default BlogPage;

function assertArticles(data: unknown): asserts data is ArticleType[] {
  if (typeof data === 'object' && data !== null) {
    return;
  }
  throw new Error('The card data is not an object');
}
