import React from 'react';

const Privacy = () => (
  <main className='container relative z-10 flex flex-col w-full gap-6 mb-20 grow'>
    <h1 className='text-2xl md:text-[1.75rem] lg:text-[2rem] font-extrabold'>Privacy Policy for Kidspace</h1>
    <div className='text-lg'>
      <p className='pb-2'>At Kidspace, accessible from <b>https://kidspace.id</b> , one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Kidspace and how we use it.</p>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

      <h2 className='text-xl font-extrabold pb-4 pt-2'>Log Files</h2>

      <p className='pb-2'>Kidspace follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information. Our Privacy Policy was created with the help of the <a className='text-primary-400 hover:text-secondary-300' href='https://www.privacypolicyonline.com/privacy-policy-generator/'>Privacy Policy Generator</a>.
      </p>

      <h2 className='text-xl font-extrabold pb-4 pt-2'>Cookies and Web Beacons</h2>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>Like any other website, Kidspace uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>

      <p className='pb-2'>For more general information on cookies, please read <a className='text-primary-400 hover:text-secondary-300' href='https://www.privacypolicyonline.com/what-are-cookies/'>the "Cookies" article from the Privacy Policy Generator</a>.
      </p>

      <h2 className='text-xl font-extrabold pb-4 pt-2'>Our Advertising Partners</h2>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>

      <ul>
        <li className='flex gap-2'>
          <p className='pb-2'>Google</p>
          <p className='pb-2'>
            <a className='text-primary-400 hover:text-secondary-300' href='https://policies.google.com/technologies/ads'>https://policies.google.com/technologies/ads</a>
          </p>
        </li>
      </ul>

      <h2 className='text-xl font-extrabold pb-4 pt-2'>Privacy Policies</h2>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>You may consult this list to find the Privacy Policy for each of the advertising partners of Kidspace.</p>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Kidspace, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>Note that Kidspace has no access to or control over these cookies that are used by third-party advertisers.</p>

      <h2 className='text-xl font-extrabold pb-4 pt-2'>Third Party Privacy Policies</h2>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>Kidspace's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?</p>

      <h2 className='text-xl font-extrabold pb-4 pt-2'>Children's Information</h2>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>Kidspace does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

      <h2 className='text-xl font-extrabold pb-4 pt-2'>Online Privacy Policy Only</h2>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Kidspace. This policy is not applicable to any information collected offline or via channels other than this website.</p>

      <h2 className='text-xl font-extrabold pb-4 pt-2'>Links</h2>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>The Kidspace website may include links to other websites which don't fall under our supervision. We cannot accept any responsibility for the privacy protection or the content of these websites.</p>

      <h2 className='text-xl font-extrabold pb-4 pt-2'>Consent</h2>

      {/* eslint-disable-next-line max-len */}
      <p className='pb-2'>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</p>
    </div>
  </main>
);

export default Privacy;
