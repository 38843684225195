import React, { FC } from 'react';
import twFocusClass from 'utils/twFocusClass';

export interface NextPrevProps {
  className?: string;
  currentPage?: number;
  totalPage?: number;
  btnClassName?: string;
  onClickNext?: () => void;
  onClickPrev?: () => void;
  onlyNext?: boolean;
  onlyPrev?: boolean;
}

const NextPrev: FC<NextPrevProps> = ({
  className = '',
  onClickNext = () => {},
  onClickPrev = () => {},
  btnClassName = 'w-10 h-10',
  onlyNext = false,
  onlyPrev = false,
}) => (
  <div
    className={`nc-NextPrev relative flex items-center text-neutral-900 ${className}`}
    data-nc-id='NextPrev'
    data-glide-el='controls'
  >
    {!onlyNext && (
    <button
      className={`${btnClassName} ${
        !onlyPrev ? 'mr-[6px]' : ''
      } bg-secondary-300 text-white hover:bg-primary-400 border border-secondary-300 rounded-full flex items-center justify-center hover:border-white ${twFocusClass()}`}
      onClick={onClickPrev}
      title='Prev'
      data-glide-dir='<'
    >
      <i className='las la-angle-left' />
    </button>
    )}
    {!onlyPrev && (
    <button
      className={`${btnClassName} bg-secondary-300 text-white hover:bg-primary-400 border border-secondary-300 rounded-full flex items-center justify-center hover:border-white ${twFocusClass()}`}
      onClick={onClickNext}
      title='Next'
      data-glide-dir='>'
    >
      <i className='las la-angle-right' />
    </button>
    )}
  </div>
);

export default NextPrev;
