import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import AppStatus from '../../app/types/appStatusTypes';
import { DetailItem } from '../../common/Card/CardDetailsList';
import CardTemplate from '../../common/Card/CardTemplate';
import LoadingProgramPage from '../../common/Loading/LoadingProgramPage';
import { CardPath } from '../../routes/types/routerTypes';
import { useAppDispatch, RootState } from '../../store';
import { searchSchoolProgram } from './state/schoolReducer';
import { SchoolProgramType, SchoolParamsType } from './types/schoolTypes';

const SchoolProgramCard = () => {
  const {
    provider, district, program, city,
  } = useParams<keyof SchoolParamsType>() as SchoolParamsType;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchSchoolProgram({
      district, program, city,
    }));
  }, []);

  const cardData: unknown = useSelector<RootState>((state) => state.school.program);
  const appStatus = useSelector<RootState>((state) => state.appState.status);

  assertSchoolData(cardData);

  const schoolProgramDetails: DetailItem[] = [
    { detail: 'Age:', content: `${cardData.age} years` },
    { detail: 'Language:', content: cardData.language },
    { detail: 'Month long:', content: `${cardData.monthsLong} months (${cardData.workingHours})` },
    { detail: 'Single drop off:', content: `${cardData.hasSingleDropOff ? 'yes' : 'no'}` },
  ];

  if (appStatus === AppStatus.NOT_FOUND) {
    return <Navigate to='/404' />;
  }

  if (appStatus === AppStatus.LOADING) {
    return <LoadingProgramPage />;
  }

  return (
    <CardTemplate
      cardPath={CardPath.SCHOOL_PROGRAM}
      urlCity={city}
      urlDistrict={district}
      urlProviderName={provider}
      cardData={cardData}
      cardDetails={schoolProgramDetails}
    />
  );
};

export default SchoolProgramCard;

function assertSchoolData(spaceData: unknown): asserts spaceData is SchoolProgramType {
  if (typeof spaceData === 'object' && spaceData !== null) {
    return;
  }
  throw new Error('The card data is not an object');
}
