import React from 'react';
import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import Page404 from 'common/Page404/Page404';
import BlogPage from 'modules/blog/BlogPage';
import UploadPage from 'common/UploadPage/UploadPage';
import Privacy from '../common/Privacy/Privacy';
import Terms from '../common/Terms/Terms';
import AllProvidersPage from '../modules/all-providers/AllProvidersPage';
import ArticlePage from '../modules/article/ArticlePage';
import ClassProgramCard from '../modules/school/ClassProgramCard';
import PlaygroundCard from '../modules/playground/PlaygroundCard';
import ProviderCard from '../modules/provider/ProviderPage';
import PlaygroundInSchoolCard from '../modules/school/PlaygroundInSchoolCard';
import SchoolProgramCard from '../modules/school/SchoolProgramCard';
import StoreInSchoolCard from '../modules/school/StoreInSchoolCard';
import StoreCard from '../modules/store/StoreCard';
import SiteFooter from '../common/Footer/SiteFooter';
import SiteHeader from '../common/Header/SiteHeader';
import ScrollToTop from './components/ScrollToTop';
import { Page } from './types/routerTypes';

export const pages: Page[] = [
  { path: '/', exact: true, component: AllProvidersPage },
  { path: '/#', exact: true, component: AllProvidersPage },
  //
  { path: '/:city/:district/providers/:name', exact: true, component: ProviderCard },
  { path: '/providers/all', exact: true, component: AllProvidersPage },
  //
  { path: '/:city/:district/providers/:provider/school-program/:program', exact: true, component: SchoolProgramCard },
  { path: '/:city/:district/providers/:provider/class-program/:program', exact: true, component: ClassProgramCard },
  { path: '/:city/:district/providers/:provider/playground/:program', exact: true, component: PlaygroundInSchoolCard },
  { path: '/:city/:district/providers/:provider/store/:program', exact: true, component: StoreInSchoolCard },
  //
  { path: '/:city/:district/providers/playground/:name', exact: true, component: PlaygroundCard },
  { path: '/:city/:district/providers/store/:name', exact: true, component: StoreCard },
  //
  { path: '/blog', component: BlogPage },
  { path: '/blog/:name', component: ArticlePage },
  { path: '/upload', component: UploadPage },
  { path: '/terms', component: Terms },
  { path: '/privacy', component: Privacy },
];

const MyRoutes = () => (
  <BrowserRouter
    basename='/'
  >
    <ScrollToTop />
    <SiteHeader />

    <Routes>
      {pages.map(({ component, path }) => {
        const Component = component;
        return <Route key={path} element={<Component />} path={path} />;
      })}
      <Route path='/404' element={<Page404 />} />
      <Route path='*' element={<Navigate to='/404' replace />} />
    </Routes>
    <SiteFooter />
  </BrowserRouter>
);

export default MyRoutes;
