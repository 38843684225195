import parse from 'html-react-parser';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import NcImage from '../../../shared/NcImage/NcImage';
import { ArticleType } from '../types/blogTypes';
import PostCardMeta from './PostCardMeta';

type ArticlePreviewPropsType = {
  article: ArticleType
  index: number
}

const ArticlePreview: FC<ArticlePreviewPropsType> = ({ article, index }) => {
  const {
    imageUrl, description, title, readingTime, date, slugName,
  } = article;

  const gridPosition = (index % 5 <= 2) ? 'xl:col-span-2' : 'xl:col-span-3';
  const oddRowImageHeight = (index % 5 <= 2) ? '' : 'xl:h-[345px]';

  return (
    <div className={`${gridPosition} relative px-4 py-4 flex w-full rounded-[20px] border border-neutral-200 flex-col justify-between items-center`} key={index}>
      <Link
        to={`${slugName}`}
        title={title}
        className='flex flex-col gap-4 w-full relative overflow-hidden'
      >
        <div className='overflow-hidden group rounded-[20px]'>
          <NcImage
            containerClassName='w-full'
            className={`nc-will-change-transform h-[216px] ${oddRowImageHeight} object-cover w-full group-hover:scale-110 transform transition-transform duration-300 bg-secondary-300`}
            src={imageUrl}
            title={title}
          />
        </div>

        <PostCardMeta date={date} readingTime={readingTime} />

        <div className='nc-card-title flex flex-col gap-2'>
          <h2 className='block text-2xl leading-tight font-semibold text-neutral-900'>
            {title}
          </h2>
          <span className='line-clamp-3'>
            {description ? parse(description) : ''}
          </span>
        </div>
      </Link>
    </div>
  );
};

export default ArticlePreview;
