import React, { FC } from 'react';
import parse from 'html-react-parser';

export type DetailItem = {
  detail: string,
  content: string,
}

type ProviderDetailsListPropsType = {
  details: DetailItem[]
}

const ProviderDetailsList: FC<ProviderDetailsListPropsType> = ({ details }) => (
  <ul className='flex flex-col gap-4'>
    { details.map((d, i) => {
      const parsedContent = d.content ? parse(d.content) : '';
      return (
        <li className='flex justify-start items-baseline gap-8' key={i}>
          <div className='text-sm min-w-[80px]'>{d.detail}</div>
          <div className='text-base md:text-lg'>{parsedContent}</div>
        </li>
      );
    }) }
  </ul>
);

export default ProviderDetailsList;
