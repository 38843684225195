import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import AppStatus from '../../app/types/appStatusTypes';
import { DetailItem } from '../../common/Card/CardDetailsList';
import CardTemplate from '../../common/Card/CardTemplate';
import LoadingProgramPage from '../../common/Loading/LoadingProgramPage';
import { CardPath } from '../../routes/types/routerTypes';
import { useAppDispatch, RootState } from '../../store';
import { searchStore } from '../store/state/storeReducer';
import { StoreType } from '../store/types/storeTypes';
import { SchoolParamsType } from './types/schoolTypes';

const StoreInSchoolCard = () => {
  const {
    provider, district, program, city,
  } = useParams<keyof SchoolParamsType>() as SchoolParamsType;

  const dispatch = useAppDispatch();

  const name = provider;

  useEffect(() => {
    dispatch(searchStore({
      name, district, city,
    }));
  }, []);

  const cardData: unknown = useSelector<RootState>((state) => state.store.store);
  const appStatus = useSelector<RootState>((state) => state.appState.status);

  assertClassData(cardData);

  const storeDetails: DetailItem[] = [
    { detail: 'Age:', content: `${cardData.age} years` },
    { detail: 'Language:', content: cardData.language },
    { detail: 'Schedule:', content: cardData.schedule },
    { detail: 'Working hours:', content: cardData.workingHours },
  ];

  if (appStatus === AppStatus.NOT_FOUND) {
    return <Navigate to='/404' />;
  }

  if (appStatus === AppStatus.SUCCEEDED) {
    return <LoadingProgramPage />;
  }

  return (
    <CardTemplate
      cardPath={CardPath.PLAYGROUND}
      urlCity={city}
      urlDistrict={district}
      urlProviderName={provider}
      cardData={cardData}
      cardDetails={storeDetails}
    />
  );
};

export default StoreInSchoolCard;

function assertClassData(spaceData: unknown): asserts spaceData is StoreType {
  if (typeof spaceData === 'object' && spaceData !== null) {
    return;
  }
  throw new Error('The space mock-data is not an object');
}
