import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const LoadingAllProviders = () => {
  const baseColor = '#E8E3E1';
  const highlightColor = '#F4A382';

  return (
      <main className='container flex flex-col w-full space-y-6 mb-20 grow'>
        <div className='flex'>
          <h2 className='text-2xl md:text-[1.75rem] font-extrabold'>
            <span>All providers</span>
          </h2>
        </div>
        <SkeletonTheme
          baseColor={baseColor}
          highlightColor={highlightColor}
          duration={2}
          borderRadius={8}
        >
          <div className='w-full flex flex-col space-y-6'>
            <Skeleton height={24} width={300} />
            <Skeleton height={24} width={300} />
            <Skeleton height={24} width={300} />
            <Skeleton height={24} width={300} />
            <Skeleton height={24} width={300} />
          </div>
        </SkeletonTheme>
      </main>

  );
};

export default LoadingAllProviders;
