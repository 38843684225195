import { Link } from 'react-router-dom';
import React from 'react';
import { Helmet } from 'react-helmet';
import NcImage from 'shared/NcImage/NcImage';
import Button from '../../shared/Button/Button';
import SocialList from '../../shared/SocialList/SocialList';
import logoImage from '../../assets/images/logos/logo-header.svg';
import I404svg from '../../assets/images/page404/page404image.svg';
import text404svg from '../../assets/images/page404/page404text.svg';
import Logo from '../../shared/Logo/Logo';

const Page404: React.FC = () => (
  <div className='bg-primary-400 h-full flex items-center justify-center flex flex-col flex-shrink-0 h-screen'>
    <Helmet>
      <title>Not Found</title>
    </Helmet>
    <div className='container relative pt-8 md:pt-7 pb-6 md:pb-8 relative flex justify-between items-center'>
      <div className='flex items-center justify-start'>
        <Logo
          img={logoImage}
          link='/providers/all'
          className='rounded-full'
        />
      </div>
      <div className='flex items-center justify-end'>
        <SocialList />
      </div>
    </div>
    <div className='container justify-center flex lg:gap-16 gap-4 grow'>
      <div className='flex flex-col md:px-8 gap-4 items-end justify-center'>
        <NcImage
          src={text404svg}
        />
        <div className='flex flex-col items-end text-xl md:text-2xl text-white'>
          <span>OOOps!</span>
          <span>Something went wrong</span>
          <span>Please try again!</span>
        </div>
        <div className='pt-8 self-center md:self-auto'>
          <Button
            className='bg-white text-primary-400'
          >
            <Link to='/providers/all'>
              All Providers page
            </Link>
          </Button>
        </div>
      </div>
      <div className='hidden md:flex items-center'>
        <NcImage
          src={I404svg}
          className='self-center'
        />
      </div>
    </div>
  </div>
);

export default Page404;
