import React, {
  FC, useEffect, useId, useMemo, useRef,
} from 'react';
import { Dialog } from '@headlessui/react';
import NextPrev from 'shared/NextPrev/NextPrev';
import Glide from '@glidejs/glide';
import NcImage from 'shared/NcImage/NcImage';
import imageLoading from '../../assets/images/card-photos/imageLoading.svg';
import ButtonClose from './ButtonClose';

export interface ModalPhotosProps {
  imgs?: string[];
  onClose: () => void;
  isOpen: boolean;
  initFocus?: number;
}

const ModalPhotos: FC<ModalPhotosProps> = ({
  imgs,
  isOpen,
  onClose,
  initFocus = 0,
}) => {
  const id = useId();
  const UNIQUE_CLASS = `glidejs${id.replace(/:/g, '_')}`;
  const completeButtonRef = useRef(null);

  const MY_GLIDEJS = useMemo(() => new Glide(`.${UNIQUE_CLASS}`, {
    direction:
        document.querySelector('html')?.getAttribute('dir') === 'rtl'
          ? 'rtl'
          : 'ltr',
    gap: 10,
    perView: 1,
    startAt: initFocus,
  }), [UNIQUE_CLASS, initFocus]);

  useEffect(() => {
    if (!isOpen) return;
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 10);
  }, [MY_GLIDEJS, isOpen, UNIQUE_CLASS]);

  const renderSlider = () => (
    <div
      className={`modalPhotos-single-gallery ${UNIQUE_CLASS} group relative flex flex-col z-50 w-full h-full`}
    >
      <div
        className='controls_nav glide__bullets mb-4'
        data-glide-el='controls[nav]'
      >
        {imgs?.map((_, index) => (
          <div key={index} className='text-center text-primary-400 hidden text-sm'>
            <span className='text-xl font-semibold'>
              {' '}
              {index + 1}
            </span>
            <span>
              {' '}
              /
              {imgs.length}
            </span>
          </div>
        ))}
      </div>
      {/*  */}

      <div
        className='glide__track max-h-full h-full relative z-50'
        data-glide-el='track'
      >
        <ul className='glide__slides h-full '>
          {imgs?.map((item, index) => (
            <li className='glide__slide relative h-full' key={index}>
              <NcImage
                src={item}
                containerClassName=' w-full h-full flex items-center justify-center rounded-2xl'
                className='max-w-full max-h-full rounded-2xl bg-primary-50'
                customLoadingImage={imageLoading}
              />
            </li>
          ))}
        </ul>
      </div>
      {/*  */}
      <div className='xl:absolute z-20 xl:-inset-x-20 max-w-6xl my-2 mx-auto top-full xl:top-1/2 transform xl:-translate-y-1/2 flex xl:justify-between glide__arrows'>
        <NextPrev
          onlyPrev
          className='mr-1.5'
          btnClassName='w-8 h-8 md:w-10 md:h-10 '
        />
        <NextPrev
          onlyNext
          className='ml-1.5'
          btnClassName='w-8 h-8 md:w-10 md:h-10 '
        />
      </div>
    </div>
  );

  const renderModalPhotos = () => (
    <Dialog
      initialFocus={completeButtonRef}
      as='div'
      className='ProductDetailModalPhotos fixed inset-0 z-50 overflow-y-auto dark bg-primary-50 text-neutral-200 hiddenScrollbar'
      onClose={onClose}
      open={isOpen}
    >
      <div className='min-h-screen px-4 text-center'>
        <Dialog.Overlay className='fixed inset-0 bg-primary-50' />
        <div
          ref={completeButtonRef}
          className='fixed left-4 top-4 md:top-4 md:left-4 z-[100]'
        >
          <ButtonClose className=' w-10 h-10 md:w-12 md:h-12 text-primary-400 hover:bg-primary-400 hover:text-white' onClick={onClose} />
        </div>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className='inline-block h-screen align-middle'
          aria-hidden='true'
        >
          &#8203;
        </span>

        <div className='relative inline-block w-full max-w-5xl py-5 sm:py-8 h-screen align-middle mx-auto'>
          {renderSlider()}
        </div>
      </div>
    </Dialog>
  );

  return renderModalPhotos();
};

export default ModalPhotos;
