import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { NavType } from '../../shared/NavList/NavList';
import SocialList from '../../shared/SocialList/SocialList';

export interface HeaderMobileNavListPropsType {
isOpened: boolean;
  list: NavType[];
}

const HeaderMobileNavList: FC<HeaderMobileNavListPropsType> = ({
  list,
  isOpened,
}) => (
  <div className={`${isOpened ? 'flex' : 'hidden'} absolute bottom-100 bg-primary-50 justify-center w-screen h-[3000px] z-20`}>
    <div className='flex flex-col items-center justify-start gap-1'>
      { list.map((el: NavType, i) => (
        el.isExternal === true
          ? (
            <a key={i} href={el.link} title='header link' className='flex py-2 px-2 flex-col'>
              <span className='text-base text-primary-400 font-extrabold opacity-80 hover:opacity-100'>{el.name}</span>
            </a>
          )
          : (
            <Link key={i} to={el.link} className='flex py-2 px-2 flex-col'>
              <span className='text-base text-primary-400 font-extrabold opacity-80 hover:opacity-100'>{el.name}</span>
            </Link>
          )
      ))}
      <div className='md:hidden mt-6'>
        <SocialList />
      </div>
    </div>
  </div>
);

export default HeaderMobileNavList;
