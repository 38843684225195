import axios from 'axios';
import { ProviderLetterList } from '../modules/all-providers/types/allProvidersTypes';
import { PlaygroundType } from '../modules/playground/types/playgroundTypes';
import { ProviderType } from '../modules/provider/types/providersTypes';
import {
  SchoolProgramType,
  ClassProgramType,
} from '../modules/school/types/schoolTypes';
// eslint-disable-next-line import/named
import { StoreType } from '../modules/store/types/storeTypes';

export interface ProviderResponseType {
  statusCode: number;
  provider: ProviderType;
}

export interface ProvidersListResponseType {
  statusCode: number;
  providers: ProviderLetterList[];
}

export interface SchoolProgramResponseType {
  statusCode: number;
  schoolProgram: SchoolProgramType;
}

export interface ClassProgramResponseType {
  statusCode: number;
  classProgram: ClassProgramType;
}

export interface StoreResponseType {
  statusCode: number;
  store: StoreType;
}

export interface PlaygroundResponseType {
  statusCode: number;
  playground: PlaygroundType;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
});

export const providerAPI = {
  searchProvider(name: string, district: string, city: string) {
    return instance.get<ProviderResponseType>('search.provider', {
      params: { name, district, city },
    });
  },
  searchSchoolProgram(program: string, district:string, city: string) {
    return instance.get<SchoolProgramResponseType>('search.school.program', {
      params: { program, district, city },
    });
  },
  searchClassProgram(program: string, district:string, city: string) {
    return instance.get<ClassProgramResponseType>('search.class.program', {
      params: { program, district, city },
    });
  },
  searchStore(name: string, district:string, city: string) {
    return instance.get<StoreResponseType>('search.store', {
      params: { name, district, city },
    });
  },
  searchPlayground(name: string, district:string, city: string) {
    return instance.get<PlaygroundResponseType>('search.playground', {
      params: { name, district, city },

    });
  },
  getProvidersList() {
    return instance.get<ProvidersListResponseType>('get.providers.list');
  },
};
