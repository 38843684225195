import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import AppStatus from '../../app/types/appStatusTypes';
import { useAppDispatch, RootState } from '../../store';
import { ArticleType } from '../blog/types/blogTypes';
import ArticleBreadcrumbs from './components/ArticleBreadcrumbs';
import ArticleFooter from './components/ArticleFooter';
import ArticleSection from './components/ArticleSection';
import ArticleSidebar from './components/ArticleSidebar';
import {
  getPromoProviders,
  getPromoArticles,
  ArticleStateType, searchArticle,
} from './state/articleReducer';
import { ProviderPromo, ArticlePromo, ArticleParamsType } from './types/articleTypes';

const ArticlePage = () => {
  const { name } = useParams<keyof ArticleParamsType>() as ArticleParamsType;
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchArticle(name));
    dispatch(getPromoArticles(name));
    dispatch(getPromoProviders());
  }, [name]);

  const articlePageData: unknown = useSelector<RootState>((state) => state.article);
  const appStatus = useSelector<RootState>((state) => state.appState.status);

  assertArticlePageData(articlePageData);

  const providersPromo = articlePageData.providersPromo as ProviderPromo[];
  const articlesPromo = articlePageData.articlesPromo as ArticlePromo[];
  const article = articlePageData.article as ArticleType;

  if (appStatus === AppStatus.NOT_FOUND) {
    return <Navigate to='/404' />;
  }

  return (
    <main className='nc-PageSingle flex w-full grow'>
      <Helmet>
        <title>{article.title}</title>
      </Helmet>
      <h1 className='visually-hidden'>{article.title}</h1>
      <div className='flex container flex-col gap-8 md:gap-[30px]'>
        <ArticleBreadcrumbs articleTitle={article.title} />
        <div className='flex flex-col gap-8 md:gap-[64px] mb-[50px] md:mb-20'>
          <div className='flex flex-col justify-between lg:flex-row gap-8 md:gap-[64px]'>
            <ArticleSection
              title={article.title}
              content={article.content}
              imageUrl={article.imageUrl}
              date={article.date}
              description={article.description}
              readingTime={article.readingTime}
            />
            <ArticleSidebar articlesPromo={articlesPromo} />
          </div>
          {/* <ArticleFooter providersPromo={providersPromo} /> */}
        </div>
      </div>
    </main>
  );
};

export default ArticlePage;

function assertArticlePageData(data: unknown): asserts data is ArticleStateType {
  if (typeof data === 'object' && data !== null) {
    return;
  }
  throw new Error('The card data is not an object');
}
