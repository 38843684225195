import { ProviderType } from '../types/providersTypes';

// eslint-disable-next-line import/prefer-default-export
export const classProviderBlackPanther: ProviderType = {
  slugName: 'black-panther-ubud',
  providerPreviewImage: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-2.jpg',
  city: { name: 'Bali', slugName: 'bali' },
  district: { name: 'Kuta', slugName: 'kuta' },
  name: 'Black Panther Ubud',
  language: 'English, Russian',
  description: 'Parkour is the art of movement and complete control of your body and mind. For more than 10 years, our team has been training from the smallest ninja to professional athletes. Starting to practice Parkour will change you forever, as your body will begin to feel stronger and faster, and you yourself will begin to feel like a real super hero.',
  telegram: 'https://t.me/Danila_Spirtus',
  whatsappLink: '+6287776633866',
  instagramLink: 'https://www.instagram.com/black_panther_parkour_park/',
  facebookLink: '',
  siteLink: '',
  schoolPrograms: [],
  googleMapsID: 'ChIJVXBfs3w90i0R1Z9BCn6UpOk',
  googleMapsRating: 5,
  tags: ['123'],
  playgrounds: [],
  stores: [],
  classPrograms: [
    {
      slugName: 'parkour',
      age: '4-14',
      type: 'Parkour',
      language: 'Eng, Rus',
      price: 'from 150 000 idr',
      image: 'https://kidspace.s3.ap-southeast-1.amazonaws.com/Bali/Ubud/black-panther-ubud/class-program/parkour-kids-1.jpg',
    },
  ],
};
