import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { PlaygroundType } from '../../modules/playground/types/playgroundTypes';
import {
  ClassProgramType,
  SchoolProgramType,
} from '../../modules/school/types/schoolTypes';
import { StoreType } from '../../modules/store/types/storeTypes';
import { CardPath } from '../../routes/types/routerTypes';
import ShareButton from '../../shared/ShareButton/ShareButton';
import CardAvailableForSale from './CardAviableForSale';
import CardBreadcrumbs from './CardBreadcrumbs';
import CardCityDistrict from './CardCityDistrict';
import CardContact from './CardContacts';
import CardDescription from './CardDescription';
import CardDetailsList, { DetailItem } from './CardDetailsList';
import CardPhotos from './CardPhotos';
import CardPrice from './CardPrice';
import { CardSocial } from './CardSocialList';
import CardTitle from './CardTitle';

type CardDataType = SchoolProgramType | ClassProgramType | PlaygroundType | StoreType

type CardTemplateProps = {
  urlCity: string,
  urlDistrict: string,
  urlProviderName: string,
  cardData: CardDataType
  cardDetails: DetailItem[]
  cardPath: CardPath
}

const CardTemplate:FC<CardTemplateProps> = ({
  urlCity,
  urlDistrict,
  urlProviderName,
  cardData,
  cardDetails,
  cardPath,
}) => {
  const cardSocial: CardSocial[] = [
    { name: 'instagram', link: cardData.instagramLink },
    { name: 'whatsapp', link: `https://wa.me/${cardData.whatsappLink}` },
    { name: 'telegram', link: cardData.telegram },
    { name: 'siteLink', link: cardData.siteLink },
  ];

  return (
    <main className='container relative z-10 flex flex-col w-full mb-[50px] mb-[70px] grow gap-6 '>
      <Helmet>
        <title>{`Kidspace: ${cardData.name} - ${cardData.type}`}</title>
        <meta name='keywords' content={cardData.tags ? cardData.tags.join(' ') : ''} />
      </Helmet>
      <h1 className='visually-hidden'>{`${cardData.name} - ${cardData.type}`}</h1>
      <CardBreadcrumbs
        cardPath={cardPath}
        city={cardData.city?.slugName}
        district={cardData.district?.slugName}
        provider={cardData.name}
        providerSlugName={urlProviderName}
        program={cardData.type}
      />
      <div className='flex flex-col lg:rounded-[20px] lg:border lg:border-neutral-200 lg:p-8 gap-6'>
        <CardPhotos photos={cardData.images} />
        <div className='w-full flex flex-col gap-4 md:gap-3 lg:gap-2 md:pt-2 relative'>
          <div className='absolute right-2 top-2'>
            <ShareButton
              city={cardData.city?.name}
              district={cardData.district?.name}
              provider={cardData.name}
              program={cardData.type}
              image={cardData.images ? cardData.images[0] : undefined}
            />
          </div>
          <CardTitle
            city={urlCity}
            district={urlDistrict}
            name={cardData.name}
            type={cardData.type}
            slugName={urlProviderName}
            cardPath={cardPath}
          />
          <div className='flex md:justify-start items-start gap-10'>
            <CardCityDistrict
              city={cardData.city?.name}
              district={cardData.district?.name}
              googleMapsID={cardData.googleMapsID}
              className='flex-col md:flex-row gap-0'
            />
          </div>
        </div>
        <div className='flex flex-col md:flex-row gap-6 xl:gap-0 items-start justify-between flex-wrap'>
          <div className='xl:w-fit'>
            <CardContact phone={cardData.whatsappLink} socials={cardSocial} />
          </div>
          <div className='grow xl:grow-0'>
            {'whatTheySell' in cardData
              ? <CardAvailableForSale saleOptions={cardData.whatTheySell} />
              : <CardPrice price={cardData.price} />}
          </div>
          <div className='xl:w-[357px] w-full'>
            <CardDetailsList details={cardDetails} />
          </div>
        </div>
        <CardDescription
          description={'programDescription' in cardData ? cardData.programDescription : cardData.description}
          comments={cardData.comments}
        />
      </div>

    </main>
  );
};

export default CardTemplate;
