import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import logoImage from '../../assets/images/logos/logo-footer.png';
import Logo from '../../shared/Logo/Logo';
import NavList, { NavType } from '../../shared/NavList/NavList';
import SocialList from '../../shared/SocialList/SocialList';

const blogFooterNavLinks: NavType[] = [
  { name: 'About', link: 'https://www.kidspace.id/#about', isExternal: true },
  { name: 'For investors', link: 'https://kidspace-id.notion.site/Kidspace-product-market-money-team-vision-b24d0ee04275432c9c0721df6a45b37a', isExternal: true },
  { name: 'Terms & Conditions', link: '/terms' },
  { name: 'All Providers', link: '/providers/all' },
  { name: 'Partnership', link: 'https://www.kidspace.id/#business_model', isExternal: true },
  { name: 'Privacy', link: '/privacy' },
];

const providerFooterNavLinks: NavType[] = [
  { name: 'About', link: 'https://www.kidspace.id/#about', isExternal: true },
  { name: 'For investors', link: 'https://kidspace-id.notion.site/Kidspace-product-market-money-team-vision-b24d0ee04275432c9c0721df6a45b37a', isExternal: true },
  { name: 'Terms & Conditions', link: '/terms' },
  { name: 'Blog', link: '/blog' },
  { name: 'Partnership', link: 'https://www.kidspace.id/#business_model', isExternal: true },
  { name: 'Privacy', link: '/privacy' },
];

const SiteFooter = () => {
  const location = useLocation();
  const [isBlog, setIsBlog] = useState(false);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (location.pathname.startsWith('/blog')) {
      setIsBlog(true);
    } else {
      setIsBlog(false);
    }
  }, [location]);

  if (location.pathname === '/404') {
    return null;
  }

  return (
    <div className='top-0 w-full left-0 right-0 z-10 bg-primary-400'>
      <div className='container flex flex-col py-4 md:py-[30px]'>
        <div className='relative grid grid-cols-[auto_auto] md:grid-cols-[auto_1fr_auto] lg:grid-cols-7 justify-between items-center'>
          <div className='flex flex-col w-[93px] h-[93px] items-start justify-start row-start-2 md:row-start-auto lg:col-span-2'>
            <Logo
              img={logoImage}
              link={isBlog ? '/blog' : '/providers/all'}
            />
          </div>
          <div className='col-span-2 md:col-span-1 lg:col-span-3'>
            <NavList list={isBlog ? blogFooterNavLinks : providerFooterNavLinks} />
          </div>
          <div className='flex items-center justify-end row-start-2 md:row-start-auto lg:col-span-2'>
            <SocialList />
          </div>
        </div>
        <span className='md:pl-2 text-sm text-white md:self-start self-center opacity-80'>ⓒ {currentYear} Sola Lab Ltd. All right reserved</span>
      </div>
    </div>
  );
};

export default SiteFooter;
