import React from 'react';
import ReactDOM from 'react-dom/client';
//
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'rc-slider/assets/index.css';
// STYLE
import './assets/styles/index.scss';
import './index.css';
import './assets/fonts/line-awesome-1.3.0/css/line-awesome.css';

// Redux
import { Provider } from 'react-redux';

import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
