import React, { FC, useState } from 'react';
import { classProviderBlackPanther } from '../../modules/provider/mock-data/providers';
import Select from './Select';
import FormItem from './FormItem';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UploadPathProps {
  onChange: any;
}

const UploadPath: FC<UploadPathProps> = ({ onChange }) => {
  const result = {
    city: 'Bali',
    district: 'Amed',
    providerSlugName: '',
    programType: 'school-program',
    programSlugName: '',
  };

  const [state, setSelectedValue] = useState([classProviderBlackPanther]);
  onChange(result);

  function setCity(this: any, choice: React.ChangeEvent<HTMLSelectElement>): void {
    result.city = choice.target.value;
    onChange(result);
  }

  function setDistrict(this: any, choice: React.ChangeEvent<HTMLSelectElement>): void {
    result.district = choice.target.value;
    onChange(result);
  }

  function setProviderSlugName(this: any, choice: React.ChangeEvent<HTMLSelectElement>): void {
    result.programSlugName = choice.target.value;
    onChange(result);
  }

  function setProgramType(this: any, choice: React.ChangeEvent<HTMLSelectElement>): void {
    result.programType = choice.target.value;
    onChange(result);
  }

  function setProgramSlugName(this: any, choice: React.ChangeEvent<HTMLSelectElement>): void {
    result.programSlugName = choice.target.value;
    onChange(result);
  }

  return (
    <div className='mt-5 mb-auto'>
      <span className='text-lg font-semibold'>Сhoose a path to save the file to S3 Bucket:</span>
      <span> /:city/:district/:slugName/school-program/:programSlugName/ </span>
      {/* FORM */}
      {/* /:city/:district/:slugName/school-program/:programSlugName/ */}
      <form name='control-ref' className='flex flex-row justify-around my-10'>
        {/* ITEMS */}
        <FormItem
          label='City'
          desc='Add city'
        >
          <select
            name='city'
            value={state.map((item) => (item.city.name))}
            onChange={(choice) => setCity(choice)}
          >
            {state.map((option) => (
              <option value={option.city.slugName}>{option.city.name}</option>
            ))}
          </select>
        </FormItem>
        <FormItem
          label='District'
          desc='Add district'
        >
          <select
            name='district'
            value={state.map((item) => (item.district.name))}
            onChange={(choice) => setDistrict(choice)}
          >
            {state.map((option) => (
              <option value={option.district.slugName}>{option.district.name}</option>
            ))}
          </select>
        </FormItem>
        <FormItem
          label='Provider Name'
          desc='Add Provider slugname'
        >
          <select
            name='providerSlugName'
            value={state.map((item) => (item.slugName))}
            onChange={(choice) => setProviderSlugName(choice)}
          >
            {state.map((option) => (
              <option value={option.slugName}>{option.slugName}</option>
            ))}
          </select>
        </FormItem>
        <FormItem
          label='Program Type'
          desc='Add program type'
        >
          <Select onChange={(choice) => setProgramType(choice)}>
            <option value='school-program'>School program</option>
            <option value='class-program'>Class program</option>
            <option value='event'>Event</option>
            <option value='playground'>Playground</option>
            <option value='store'>Store</option>
          </Select>
        </FormItem>

        <FormItem
          label='Program Name'
          desc='Add program slugname'
        >
          <select
            name='programSlugName'
            value={state.map((item) => (item.slugName))}
            onChange={(choice) => setProgramSlugName(choice)}
          >
            {state.map((option) => (
              <option value={option.slugName}>{option.slugName}</option>
            ))}
          </select>
        </FormItem>
      </form>
    </div>
  );
};

export default UploadPath;
