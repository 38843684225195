import React, { useState, FC } from 'react';
import ModalPhotos from '../../shared/ModalPhotos/ModalPhotos';
import NcImage from '../../shared/NcImage/NcImage';
import noMainImage from '../../assets/images/card-photos/noMainImage.svg';
import noSecondaryImage from '../../assets/images/card-photos/noSecondaryImage.svg';

type CardPhotosPropsType = {
  photos?: string[]
}

const CardPhotos: FC<CardPhotosPropsType> = ({ photos }) => {
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const handleCloseModal = () => setIsOpen(false);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  if (!photos || photos.length === 0) {
    photos = ['', '', ''];
  }

  if (photos.length === 1) {
    photos = photos.concat(['', '']);
  }

  if (photos.length === 2) {
    photos = photos.concat(['']);
  }

  return (
    <div className='relative grid grid-cols-2 md:grid-cols-[761fr_428fr] xl:grid-cols-[64fr_36fr] gap-2 sm:gap-2'>
      <div
        className='row-span-2 relative rounded-md sm:rounded-xl overflow-hidden'
      >
        <NcImage
          containerClassName='absolute inset-0'
          className='object-cover object-center w-full h-full rounded-md sm:rounded-xl bg-secondary-300'
          src={photos[0] || noMainImage}
        />
        {photos[0] !== '' && (
        <div
          className='absolute cursor-pointer inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'
          onClick={() => handleOpenModal(0)}
        />
        )}
      </div>
      <div className='row-span-2 space-y-2'>
        {photos?.filter((_, i) => i >= 1 && i < 3)
          .map((item, index) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                index >= 2 ? 'block' : ''
              }`}
            >
              <NcImage
                containerClassName='h-[137px] md:h-[214px] xl:h-[251px]'
                className='object-cover object-center h-full w-full rounded-md sm:rounded-xl bg-secondary-300'
                src={item || noSecondaryImage}
              />

              {/* OVERLAY */}
              {item !== '' && (
                <div
                  className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer'
                  onClick={() => handleOpenModal(index + 1)}
                />
              )}
            </div>
          ))}
      </div>
      {photos.some((element) => element !== '')
        ? (
          <>
            <div
              className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-secondary-300 text-white cursor-pointer hover:bg-primary-400 z-10'
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                />
              </svg>
              <span className='ml-2 text-white text-sm font-medium'>
                Show all photos
              </span>
            </div>
            <ModalPhotos
              imgs={photos.filter((n) => n)}
              isOpen={isOpen}
              onClose={handleCloseModal}
              initFocus={openFocusIndex}
            />
          </>
        ) : null}
    </div>
  );
};

export default CardPhotos;
