import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppStatus from '../../app/types/appStatusTypes';
import LoadingAllProviders from '../../common/Loading/LoadingAllProviders';
import { useAppDispatch, RootState } from '../../store';
import Button from '../../shared/Button/Button';
import Input from '../../shared/Input/Input';
import { getAllProviders } from './state/AllProviderReducer';
import { ProviderLetterList } from './types/allProvidersTypes';

const AllProvidersPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllProviders());
  }, []);

  const appStatus = useSelector<RootState>((state) => state.appState.status);
  const providers: unknown = useSelector<RootState>((state) => state.allProviders.allProviders);

  assertProviders(providers);

  if (appStatus === AppStatus.LOADING) {
    return <LoadingAllProviders />;
  }

  return (
    <main className='container flex flex-col w-full gap-6 mb-20 grow'>
      <Helmet>
        <title>Kidspace: All providers page</title>
      </Helmet>
      <h1 className='visually-hidden'>All providers page</h1>
      <div className='flex flex-col-reverse lg:flex-row justify-between lg:items-center'>
        <h2 className='text-2xl md:text-[1.75rem] font-extrabold'>
          <span>All providers</span>
        </h2>
        {/* <div className='flex flex-col md:flex-row gap-4 md:gap-2'> */}
        {/*   <Input */}
        {/*     type='search' */}
        {/*     placeholder='What do you want to find?' */}
        {/*     className='w-full lg:w-[290px] focus:border-neutral-200' */}
        {/*   /> */}
        {/* eslint-disable-next-line max-len */}
        {/*   <Button className='text-lg bg-primary-400 rounded-full text-white flex items-center justify-center hover:shadow-[0_4px_20px_rgba(0,0,0,0.15)]'> */}
        {/*     Search */}
        {/*   </Button> */}
        {/* </div> */}
      </div>
      {providers.map((category, index) => (
        <div key={index} className='w-full flex flex-col space-y-6'>
          <h3 className='text-[1.625rem] font-extrabold'>{category.letter}</h3>
          <ul className='grid grid-cols-1 md:grid-cols-3 gap-x-16 gap-y-4'>
            {category.providersByLetter.map((provShort, i) => {
              let providerLink = `/${provShort.city.slugName}/${provShort.district.slugName}/providers/${provShort.slugName}`;
              // eslint-disable-next-line max-len
              if (provShort.isStore && !provShort.isSchool && !provShort.isClass && !provShort.isSquare) {
                providerLink = `/${provShort.city.slugName}/${provShort.district.slugName}/providers/store/${provShort.slugName}`;
              }
              // eslint-disable-next-line max-len
              if (provShort.isSquare && !provShort.isSchool && !provShort.isClass && !provShort.isStore) {
                providerLink = `/${provShort.city.slugName}/${provShort.district.slugName}/providers/playground/${provShort.slugName}`;
              }
              return (
                <li key={i}>
                  <Link
                    className='hover:text-primary-400 text-lg'
                    to={providerLink}
                  >
                    {provShort.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </main>
  );
};

export default AllProvidersPage;

function assertProviders(data: unknown): asserts data is ProviderLetterList[] {
  if (typeof data === 'object' && data !== null) {
    return;
  }
  throw new Error('The card data is not an object');
}
