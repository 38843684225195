import React, { FC } from 'react';

export type DetailItem = {
  detail: string,
  content: string,
}

type CardDetailsListPropsType = {
  details: DetailItem[]
}

const CardDetailsList: FC<CardDetailsListPropsType> = ({ details }) => (
  <div className='flex justify-start'>
    <ul className='w-fit flex flex-col gap-4'>
      { details.map((d, i) => (
        <li className='flex' key={i}>
          <div className='flex items-baseline'>
            <span className='text-sm min-w-[120px] grow'>{d.detail}</span>
            <span className='text-base md:text-lg pl-4'>{d.content}</span>
          </div>
        </li>
      )) }
    </ul>
  </div>

);

export default CardDetailsList;
